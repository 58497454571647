/*Base Styles*/

html {
  height: 100%;

  input[type='datetime-local'],
  input[type='date'] {
    color-scheme: dark;
  }
}

:root {
  font-size: 16px;
}

body {
  height: 100%;
  overflow: hidden;
  font: {
    family: $font-family-base;
    size: $font-size-base;
    weight: $font-weight-base;
  }
}

body.ios-mobile-view-height {
  height: auto !important;
  overflow: visible !important;
}

p,
h1,
h2,
span,
strong {
  white-space: pre-line;
}

#nprogress .bar {
  background: $vpag-primary !important;
  z-index: 5000 !important;
}

#nprogress .peg {
  @include box-shadow(0 0 10px $vpag-primary, 0 0 5px $vpag-primary !important);
}

#nprogress .spinner {
  z-index: 5000 !important;
}

#nprogress .spinner-icon {
  border-left-color: $vpag-primary !important;
  border-top-color: $vpag-primary !important;
}

.ripple-effect {
  @extend %ripple-effect;
}

.text-transform-none {
  text-transform: none;
}

.br-break {
  word-break: break-all !important;
}

.font-weight-semibold {
  font-weight: 500 !important;
}

.z-index-20 {
  z-index: 20;
}

.z-index-upper-app {
  z-index: 9999;
}

.size-120 {
  @extend %size-120;
}

.size-100 {
  @extend %size-100;
}

.size-90 {
  @extend %size-90;
}

.size-80 {
  @extend %size-80;
}

.size-70 {
  @extend %size-70;
}

.size-60 {
  @extend %size-60;
}

.size-50 {
  @extend %size-50;
}

.size-40 {
  @extend %size-40;
}

.size-30 {
  @extend %size-30;
}

.size-30 {
  @extend %size-30;
}

.size-20 {
  @extend %size-20;
}

.size-10 {
  @extend %size-10;
}

.size-8 {
  @extend %size-8;
}

.border-bottom {
  border-bottom: solid 1px $gray-300;
}

div[tabindex='-1'] {
  z-index: 1200 !important;
}

div.MuiChip-root[tabindex='-1'] {
  z-index: 0 !important;
}

.joyride-overlay {
  z-index: 1510 !important;
}

.col-with-divider div[class*='col-'] {
  border-left: solid 1px $gray-300;

  &:first-child {
    border: 0 none;
  }
}

.height-100 {
  height: 100px !important;
}

.height-150 {
  height: 150px !important;
}

.height-200 {
  height: 200px !important;
}

.max-width-100 {
  max-width: 100px !important;
}

.max-height-120 {
  max-height: 120px !important;
}

.right-arrow {
  color: $app-primary;
  display: inline-block;
  text-transform: capitalize;

  &:after {
    color: $app-primary;
    content: '\f30f';
    font-family: 'Material-Design-Iconic-Font';
    font-size: 20px;
    margin-left: 8px;
    vertical-align: middle;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.letter-spacing-base {
  letter-spacing: $letter-spacing-base;
}

.letter-spacing-lg {
  letter-spacing: $letter-spacing-lg;
}

.letter-spacing-xl {
  letter-spacing: $letter-spacing-xl;
}

.widget-header,
.widget-content-expand,
.widget-content-hide {
  background-color: $vpag-dark;
  display: flex;
  height: 58px;
  justify-content: center;
  padding: 0 20px;
  position: relative;
  z-index: 3;

  .buttons-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 50%;

    @include mq(mobileLarge) {
      flex-direction: column-reverse;
    }
  }

  .widget-button {
    background-color: $vpag-primary;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-family: $font-family-base;
    height: 30px;
    margin-left: 0.8rem;
    transform: translate(-20%, -50%);
    width: 80px;

    &:disabled {
      background: rgba(225, 217, 205, 0.2);
      color: gray('900');
      cursor: not-allowed;
    }
  }

  .cancel-button {
    background-color: transparent;
    border: none;
    color: gray('100');
    cursor: pointer;
    font-family: $font-family-base;
    transform: translate(-23%, -35%);
  }

  .edit-button {
    cursor: pointer;
    width: 18px;
  }

  .widget-title {
    color: gray('600') !important;
    font: 18px $font-family-bold !important;
    margin: 0 !important;

    @include mq(mobileLarge) {
      max-width: 200px;
    }

    @include mq(mobile) {
      max-width: 150px;
    }

    &-button {
      position: absolute;
      right: 0;
      top: 50%;
    }
  }

  .collapse-button {
    background-color: transparent;
    border: none;
    bottom: 0;
    left: 47%;
    position: absolute;
  }
}

.widget-content-expand {
  align-items: center;
  display: flex;
  height: 100%;
  top: 0;
  transform: translateY(0);
  width: 100%;
  z-index: 2;
}

.widget-content-expand {
  margin-top: 0 !important;
  padding-top: 1rem;
  transition: 0.4s;
}

.widget-content-hide {
  height: 0;
  opacity: 0;
  transform: translateY(-300%);
  z-index: -3;
}

.widget-body {
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  padding: 0px 20px 20px;
}

.widget-tab-btn {
  background-color: transparent;
  border: none;
  color: $vpag-primary;
  font-size: $font-size-sm;
  font-weight: 600;
  min-height: 100%;
  min-width: 70px;
  text-transform: uppercase;
  transition: 0.2s all ease;

  &.active {
    background-color: rgba($black, 0.2);
    border-bottom: 2px solid $vpag-primary;
  }

  &:hover,
  &:active {
    background-color: rgba(gray('700'), 0.2);
  }
}

.page-heading {
  @extend %jr-card-style;
  padding: 22px 30px;
  z-index: 2;

  @media screen and (max-width: 575px) {
    margin-bottom: 18px;
    padding: 16px 20px;
  }

  & .title {
    font-weight: 700;
    text-transform: capitalize;
  }

  & .breadcrumb {
    align-items: center;
    background-color: transparent !important;
    display: flex;
    font-size: 13px;
    margin-bottom: 0;
    padding: 0 !important;

    &-item {
      align-items: center;
      color: $light-gray;
      display: flex;

      &.active {
        color: $app-primary;
      }
    }
  }
}

.sub-heading {
  color: $sub-heading-color;
  font-size: 13px;
  margin-bottom: 0;

  &:not(:last-child) {
    margin-bottom: 16px !important;
  }
}

.manage-margin {
  margin-bottom: 0;

  & > * {
    margin-bottom: 6px;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}

//Shadow
.no-shadow {
  @include box-shadow(none !important);
}

.shadow-sm {
  @include box-shadow($shadow-sm);
}

.shadow {
  @include box-shadow($shadow);
}

.shadow-lg {
  @include box-shadow($shadow-lg);
}

.shadow-xl {
  @include box-shadow($shadow-xl);
}

.avatar-shadow {
  @include box-shadow($avatar-shadow);
}

.remove-table-border {
  td,
  th {
    border: 0 none;
  }
}

//Media Object list
.media-list {
  margin-bottom: 10px;

  &:not(:last-child) {
    border-bottom: solid 1px $gray-200;
    margin-bottom: 20px;
    padding-bottom: 15px;
    position: relative;
  }
}

.border-2 {
  border: 2px solid $gray-400 !important;
}

.border-w2 {
  border-width: 2px;
}

// Border Color Classes
@each $color_name, $color in $colors_palette {
  @each $color_type, $color_value in $color {
    @if $color_type == 'base' {
      .border-#{$color_name} {
        border-color: $color_value !important;
      }
    }
  }
}

// Badge Color Classes
@each $color_name, $color in $colors_palette {
  @each $color_type, $color_value in $color {
    @if $color_type == 'base' {
      .badge-#{$color_name} {
        background-color: $color_value !important;
        color: $white;

        &[href]:hover,
        &[href]:focus {
          background-color: darken($color_value, 10%) !important;
          text-decoration: none;
        }
      }
    }
  }
}

//Center Crop image

.center-crop-img {
  min-height: 118px;
  overflow: hidden;
  position: relative;
  width: 100%;

  & img {
    height: 100%;
    left: 50%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }

  & img.portrait {
    height: auto;
    width: 100%;
  }
}

//Bootstrap Components Override

//Buttons

a.btn-info {
  @extend %link-hover;
}

a.btn-danger {
  @extend %link-hover;
}

a.btn-warning {
  @extend %link-hover;
}

a.btn-success {
  @extend %link-hover;
}

a.btn-dark {
  @extend %link-hover;
}

//Badges
a.badge-success {
  @extend %link-hover;
}

a.badge-danger {
  @extend %link-hover;
}

a.badge-warning {
  @extend %link-hover;
}

a.badge-info {
  @extend %link-hover;
}

a.badge-dark {
  @extend %link-hover;
}

a.badge-pink {
  @extend %link-hover;
}

a.badge-red {
  @extend %link-hover;
}

a.badge-purple {
  @extend %link-hover;
}

a.badge-deep-purple {
  @extend %link-hover;
}

a.badge-indigo {
  @extend %link-hover;
}

a.badge-blue {
  @extend %link-hover;
}

a.badge-light-blue {
  @extend %link-hover;
}

a.badge-cyan {
  @extend %link-hover;
}

a.badge-teal {
  @extend %link-hover;
}

a.badge-green {
  @extend %link-hover;
}

a.badge-light-green {
  @extend %link-hover;
}

a.badge-lime {
  @extend %link-hover;
}

a.badge-yellow {
  @extend %link-hover;
}

a.badge-amber {
  @extend %link-hover;
}

a.badge-orange {
  @extend %link-hover;
}

a.badge-deep-orange {
  @extend %link-hover;
}

a.badge-brown {
  @extend %link-hover;
}

a.badge-blue-gray {
  @extend %link-hover;
}

a.badge-gray {
  @extend %link-hover;
}

.font-inter {
  font-family: 'Inter' !important;
}

.font-size-20 {
  font-size: 20px !important;
}

//Rounded
.rounded-sm {
  @include border-radius($border-radius-sm !important);
}

.rounded-lg {
  @include border-radius($border-radius-lg !important);
}

.rounded-xl {
  @include border-radius($border-radius-xl !important);
}

.rounded-top-left {
  border-top-left-radius: $border-radius !important;
}

.rounded-top-right {
  border-top-right-radius: $border-radius !important;
}

.rounded-bottom-left {
  border-bottom-left-radius: $border-radius !important;
}

.rounded-bottom-right {
  border-bottom-right-radius: $border-radius !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-right-0 {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-left-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

//Light Color
.text-light {
  color: lighten($black, 60%) !important;
}

.text-light-grey {
  color: $light-gray !important;
}

a.text-light {
  &:hover,
  &:focus {
    background-color: lighten($black, 50%) !important;
  }
}

//Sepia Color
.bg-sepia {
  background-color: $sepia !important;
}

a.bg-sepia {
  &:hover,
  &:focus {
    background-color: darken($sepia, 10%) !important;
  }
}

.text-sepia {
  color: $sepia;
}

a.text-sepia {
  &:hover,
  &:focus {
    color: $sepia !important;
  }
}

.border-sepia {
  border-color: $sepia !important;
}

.btn-sepia,
a.btn-sepia {
  background-color: $sepia !important;
  border-color: $sepia !important;
  color: $white !important;

  &:hover,
  &:focus {
    background-color: darken($sepia, 15%) !important;
    border-color: darken($sepia, 15%) !important;
    color: $white !important;
  }

  &.disabled,
  &:disabled {
    background-color: $sepia !important;
    border-color: $sepia !important;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    background-color: darken($sepia, 15%) !important;
    border-color: darken($sepia, 15%) !important;
    color: $white !important;
  }
}

.btn-outline-sepia,
a.btn-outline-sepia {
  background-color: transparent !important;
  border-color: $sepia !important;
  color: $white !important;

  &:hover,
  &:focus {
    background-color: $sepia !important;
    border-color: $sepia !important;
    color: $white !important;
  }

  &.disabled,
  &:disabled {
    background-color: transparent !important;
    color: $sepia !important;
  }
}

.badge-sepia {
  background-color: $sepia !important;
}

//Sepia Color
.bg-geekblue {
  background-color: $geekblue !important;
}

a.bg-geekblue {
  &:hover,
  &:focus {
    background-color: darken($geekblue, 10%) !important;
  }
}

.text-geekblue {
  color: $geekblue;
}

a.text-geekblue {
  &:hover,
  &:focus {
    color: $geekblue !important;
  }
}

.border-geekblue {
  border-color: $geekblue !important;
}

.btn-geekblue,
a.btn-geekblue {
  background-color: $geekblue !important;
  border-color: $geekblue !important;
  color: $white !important;

  &:hover,
  &:focus {
    background-color: darken($geekblue, 15%) !important;
    border-color: darken($geekblue, 15%) !important;
    color: $white !important;
  }

  &.disabled,
  &:disabled {
    background-color: $geekblue !important;
    border-color: $geekblue !important;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    background-color: darken($geekblue, 15%) !important;
    border-color: darken($geekblue, 15%) !important;
    color: $white !important;
  }
}

.btn-outline-geekblue,
a.btn-outline-geekblue {
  background-color: transparent !important;
  border-color: $geekblue !important;
  color: $white !important;

  &:hover,
  &:focus {
    background-color: $geekblue !important;
    border-color: $geekblue !important;
    color: $white !important;
  }

  &.disabled,
  &:disabled {
    background-color: transparent !important;
    color: $geekblue !important;
  }
}

.badge-geekblue {
  background-color: $geekblue !important;
}

//Separator
.jr-separator {
  background-color: $app-primary;
  height: 2px;
  margin: 0 auto 10px;
  width: $size-20;
}

.object-cover {
  object-fit: cover;
}

.jr-border-radius {
  @include border-radius($border-radius);
}

// Custom List
.jr-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-left: -8px;
  margin-right: -8px;
  padding-left: 0;

  &__item {
    margin-bottom: 14px;
    padding-left: 8px;
    padding-right: 8px;
  }

  &-bordered &-item:not(:last-child) {
    border-right: $jr-border;
  }

  &-half &-item {
    width: 50%;

    &:nth-last-of-type(1),
    &:nth-last-of-type(2) {
      margin-bottom: 0;
    }
  }
}

// Gradient Color Class
.bg-gradient-primary {
  @include gradient-directional($app-primary, lighten($app-primary, 16%), 0deg);
}

.bg-gradient-primary-x {
  @include gradient-x(
    darken($app-primary, 10%),
    lighten($app-primary, 16%),
    70%,
    100%
  );
}

.overflow-hidden {
  overflow: hidden;
}

.embed-responsive-31by9:before {
  padding-top: 28%;

  @media screen and (max-width: 575px) {
    padding-top: 36%;
  }
}

.col-half {
  @media screen and (max-width: 400px) {
    flex: 0 0 50%;
    max-width: 50%;
    z-index: 90;
  }
}

.merchant-details {
  align-items: center !important;
  display: flex !important;
  gap: 10px;
}

.currency-box-disable,
.currency-box-enable,
.main-tracker-box,
.tracker-box {
  background-color: transparent;
  border: solid 1px $gray-600;
  color: $gray-600;
  font-size: 12px;
  padding: 0 4px;
}

.currency-box-enable,
.main-tracker-box,
.tracker-box {
  background-color: $gray-900;
  border: solid 1px $gray-900;
  color: $white;
  text-transform: capitalize;
}

.error-input-borders {
  border: solid 1px red !important;
}

.main-tracker-box {
  color: $vpag-primary;
}

.transaction-modal {
  background-color: #1d1d1b;
  height: 200px;
  width: 600px;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.line-height-1 {
  line-height: 1;
}

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.border-top-40 {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}

.border-bottom-40 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.text-disabled {
  color: rgba(255, 255, 255, 0.6);
}

// Cursor
.cursor-default {
  cursor: default !important;
}

.pointer {
  cursor: pointer !important;
}

// Min Height
.mh-50 {
  min-height: 50px;
}

.mh-100 {
  min-height: 100px;
}

.mh-150 {
  min-height: 150px;
}

.mh-200 {
  min-height: 200px;
}

.mh-300 {
  min-height: 300px;
}

.mh-400 {
  min-height: 400px;
}

.mh-500 {
  min-height: 500px;
}

.z-index-3 {
  z-index: 3;
}

.gap-xs {
  gap: 0.25rem;
}

.gap-sm {
  gap: 0.5rem;
}

.gap-1 {
  gap: 1rem;
}

.gap-2 {
  gap: 2rem;
}

.gap-3 {
  gap: 3rem;
}

.gap-4 {
  gap: 4rem;
}

.gap-5 {
  gap: 5rem;
}

.bg-gray-600 {
  background-color: gray('600') !important;
}

.bg-gray-700 {
  background-color: gray('700');
}

.bg-gray-900 {
  background-color: gray('900');
}

.page-title {
  display: grid;
  font-family: $font-family-base;
  padding-top: 1rem;
  place-content: center;
  position: absolute;
  right: 0;
  width: calc(100vw - 300px);
  z-index: -1;

  @media only screen and (max-width: 1200px) {
    position: relative;
    width: 100px;
  }
}

.limits-input-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;

  label {
    color: gray('100');
    display: flex;
    flex-direction: column;
    font-size: 12px;
    max-width: 170px;
    text-transform: uppercase;
    width: 100%;
  }

  input {
    background-color: gray('700');
    border: none;
    color: $white;
    font-size: 0.9rem;
    font-weight: 600;
    padding: 7px 10px;
    width: 100%;

    &::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.role-icon {
  svg {
    fill: gray('900');
    height: 40px;
    width: 25px;
  }

  &.active {
    svg {
      fill: $vpag-primary;
    }
  }

  &.white {
    svg {
      fill: $white;
    }
  }

  &.sm {
    svg {
      width: 20px;
    }
  }

  &.lg {
    svg {
      width: 30px;
    }
  }

  &.xl {
    svg {
      width: 35px;
    }
  }
}

.gradient-gray-hover {
  &:hover {
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.1) 13.54%,
      rgba(0, 0, 0, 0.1) 86.98%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

.currency-icon {
  align-items: center;
  background-color: gray('700');
  color: gray('100');
  display: flex;
  font-family: $font-family-bold;
  justify-content: center;
  width: 36px;

  &.active {
    background-color: $vpag-primary;
    color: gray('700');
  }
}

.td-input-field {
  background-color: rgba($black, 0.3);
  border: none;
  color: $white;
  font-size: $font-size-md;
  padding: 0 0.3rem;
  text-align: right;
  width: 84px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:disabled {
    color: rgba($white, 0.3);
    cursor: not-allowed;
    opacity: 0.7;
  }
}

.border-radius-0 {
  border-radius: 0 !important;
}

.widget-title {
  color: gray('600') !important;
  font-family: $font-family-bold;
}

.font-family-bold {
  font-family: $font-family-bold;
}

.font-family-arboria-bold {
  font-family: 'Arboria Bold', sans-serif !important;
}

.font-family-light {
  font-family: $font-family-light;
}

.letter-spacing-base {
  letter-spacing: unset;
}

.letter-spacing-lg {
  letter-spacing: $letter-spacing-lg;
}

.letter-spacing-xl {
  letter-spacing: $letter-spacing-xl;
}

.section-title {
  color: gray('600') !important;
  font: 18px $font-family-bold !important;

  span {
    color: $vpag-primary;
  }
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.top-1 {
  top: 1rem;
}

.right-1 {
  right: 1rem;
}

.bottom-1 {
  bottom: 1rem;
}

.left-1 {
  left: 1rem;
}

.sticky-top {
  position: sticky !important;
  top: 0;
  z-index: 99;
}

.qr-code {
  border-radius: 5px;
}

.fixed-footer {
  background-color: gray('600');
  bottom: 0;
  display: flex !important;
  flex-direction: row-reverse;
  left: 0;
  position: fixed;
  width: 98%;
}

.code-block {
  color: white;
  line-height: 1.5;

  code {
    color: $vpag-primary !important;
  }
}

.widget-item-hover {
  &:hover {
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.1) 13.54%,
      rgba(0, 0, 0, 0.1) 86.98%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

.modal-close-btn {
  button {
    color: white;

    &:hover {
      color: white;
      filter: brightness(0.5);
    }
  }
}

.aside-gradient {
  background: linear-gradient(90deg, #2b2d2e, #242525);
}

.close-text {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
}

.Mui-selected {
  background-color: #32373a !important;
}
