.app-login {
  &--container {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    width: 100%;

    & .loader-view {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      z-index: 2;
    }
  }

  &--wrapper {
    @include box-shadow($shadow);
    @include display-flex();

    background-color: $white;
    flex-direction: row-reverse;
    font-size: 14px;
    height: 100%;
    overflow: hidden;
    width: 100%;
  }

  &--content {
    align-items: center;
    background: gray('gradient');
    display: flex;
    flex-direction: column;
    height: 100%;
    order: 1;
    padding: 5px 0 15px;
    position: relative;
    width: 40%;

    @include mq(tablet) {
      order: 2;
      width: 100%;
    }
  }

  &--form-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    & form {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      max-width: 332px;
      height: 100%;
      width: 80%;

      @include mq(tablet) {
        height: 60%;
      }

      .input-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        & > a {
          text-decoration: underline;
        }
      }

      label {
        color: gray('100');
        font-size: 12px;
        margin-bottom: 0.3rem !important;
      }

      input {
        background-color: gray('dark');
        padding: 0.6rem 1rem;
        text-align: center;

        &::placeholder {
          color: $white;
        }

        &.error {
          border: 1px solid $danger;
        }
      }
    }
  }

  &--logo-wrapper {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    margin: 5vh 0;

    @include mq(tablet) {
      display: none;
    }
  }

  &--btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &--submit-btn {
    align-self: center;
    background-color: $vpag-primary-dark-new;
    border: none;
    color: $black;
    cursor: pointer;
    font-family: $font-family-bold;
    max-width: 332px;
    min-width: 200px;
    padding: 0.6rem 1rem;
    text-transform: capitalize;
    transition: 0.2s;
    width: 60% !important;
    border-radius: 5px;

    &:hover {
      filter: brightness(0.9);
    }
  }
}

.app-login-content .form-control {
  background-color: map_get($gray, lighten-4);

  &:focus {
    border-color: $app-primary;
    box-shadow: none;
  }
}

.app-login-content .btn,
.login-content .jr-btn {
  padding: 10px 35px !important;
}

.app-logo-content {
  background-color: $vpag-dark;
  flex-direction: column;
  order: 2;
  padding: 35px 35px 20px;
  width: 60%;

  @include mq(tablet) {
    order: 1;
    width: 100%;
  }

  & .logo-home {
    height: 22vh;
    max-height: 190px;
    max-width: 140px;
    width: 25vw;
  }

  & .app-logo-title {
    background-color: $vpag-primary;
    color: $vpag-dark;
    font-size: 14px;
    font-weight: bold;
    margin-top: 20px;
    padding: 4px 10px 2px;
    text-transform: uppercase;
  }
}

.login-button {
  width: 100%;

  & > button {
    align-self: center;
    height: 45px;
    max-width: 332px;
    width: 100%;
  }
}

.input-primary-vpag {
  label {
    align-self: center;
    position: static;
    transform: scale(0.75);
    transform: unset;
  }

  input {
    background-color: gray('100');
    outline: 0;

    &:focus {
      outline: none;
    }
    &:hover {
      outline: none;
    }
  }
}

.app-social-block {
  @include align-items(center);
  @include display-flex();
  @include justify-content(space-between);

  & .social-link,
  & .social-link li {
    margin: 0;
  }

  & .social-link button {
    @include border-radius(50%);
    border: solid 1px $app-primary;
    color: $app-primary;
    padding: 0;

    &:hover,
    &:focus {
      background-color: $app-primary;
      color: $white;
    }
  }

  & p {
    margin-bottom: 0;
  }
}
