/* Modal styles */

.modal__submit-btn {
  background-color: $vpag-primary;
  border-radius: 5px;
  border: none;
  bottom: 2rem;
  color: $black;
  height: 30px;
  position: absolute;
  right: 2rem;
  width: 100px;

  &:disabled {
    background-color: gray('700');
    color: gray('900');
  }
}

.modal__close-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;

  svg {
    fill: $white;
  }
}
.action-modal {
  &__container {
    background-color: gray('dark');
    border-radius: 10px;
    color: $white;
    max-width: 300px;
    min-height: 170px;
    overflow: hidden;
    position: relative;
    width: 80vw;
  }

  &__button-wrapper {
    bottom: 0;
    margin-top: 1rem;
    position: absolute;
    width: 100%;
  }

  &__button {
    background-color: transparent;
    border: 1px solid gray('600');
    border-bottom: none;
    color: $vpag-primary;
    font-family: $font-family-bold;
    height: 50px;
    width: 50%;

    &:hover {
      background-color: rgba($color: gray('900'), $alpha: 0.1);
    }
  }

  &__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2rem;
    min-height: 130px;
    padding: 1.5rem;
  }

  &__title {
    color: $white;
    font-size: $font-size-lg;
    text-align: center;
  }
}

.notes-editor {
  align-items: center;
  background-color: gray('dark') !important;
  display: flex;
  flex-direction: column;
  height: 200px;
  max-width: 660px;
  overflow: hidden;
  width: 80vw;

  @include mq(mobileLarge) {
    height: 300px;
  }

  &__checkbox-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-around;

    @include mq(mobileLarge) {
      align-items: start;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      width: 100%;
    }
  }

  &__divider {
    border-bottom: 1px solid rgba($color: gray('100'), $alpha: 0.4);
    height: 1px;
    width: 95%;
  }

  &__submit-btn {
    @extend .modal__submit-btn;

    bottom: 10px;
    color: $vpag-dark;
    font-family: $font-family-bold;
    right: 10px;
  }

  input::placeholder,
  textarea::placeholder {
    color: gray('100');
    font-size: $font-size-base;
  }

  input,
  textarea {
    border: none;
    color: gray('100');
    padding: 0.5rem 1rem;
    width: 100%;
  }

  input {
    width: 50%;
  }

  input:focus,
  textarea:focus {
    outline: none;
    resize: none;
  }

  select {
    background-color: transparent;
    color: $white;
    height: 100%;
    margin-left: 0.5rem;
    width: 50%;

    option {
      background-color: gray('700');
    }
  }

  &__close-btn {
    @extend .modal__close-btn;

    right: 0.7rem;
    top: 0.9rem;
  }
}

.note-item {
  display: flex;
  padding: 0.8rem 1rem;
  position: relative;
  width: 100%;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    &.hover {
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.1) 13.54%,
        rgba(0, 0, 0, 0.1) 86.98%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }
}

.notes-icon {
  height: 20px;
  position: absolute;
  right: 2px;
  top: 20px;
  width: 20px;
}

.user-avatar {
  &.warning {
    border: 1px solid $warning;
  }

  &.danger {
    border: 1px solid $danger;
  }
}

.image-preview {
  &__container {
    align-items: center;
    background-color: gray('900');
    display: flex;
    flex-direction: column;
    height: 90vh;
    max-height: 900px;
    max-width: 900px;
    padding-top: 50px;
    position: relative;
    width: 95vw;
  }

  &__img {
    max-height: 90%;
    max-width: 90%;
    overflow: hidden;
  }
}

.image-placeholder {
  align-items: center;
  background-color: rgba(gray('700'), $alpha: 0.5);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 80%;
  justify-content: center;
  width: 50%;
}

.upload-document {
  align-items: center;
  background-color: gray('dark') !important;
  display: flex;
  flex-direction: column;
  height: 400px;
  justify-content: space-between;
  max-width: 1024px;
  overflow: hidden;
  padding: 1rem 0 1rem;
  width: 356px;

  h1,
  h4 {
    color: $white;
  }

  .image-preview {
    max-height: 195px;
    max-width: 318px;
  }

  .change-file-btn,
  a {
    background-color: transparent;
    border: none;
    color: $vpag-primary;
    margin-bottom: 1rem;
    text-decoration: underline;
  }
}

.approve-document {
  &__container {
    align-items: center;
    background-color: gray('dark');
    color: $white;
    display: flex;
    flex-direction: column;
    height: 360px;
    padding: 1rem 2rem;
    width: 360px;

    @include mq(mobileLarge) {
      padding-bottom: 5rem;
      width: 85vw;

      .modal__submit-btn {
        bottom: 10px;
        left: 0;
        margin: 0 auto;
        right: 0;
      }
    }

    .content-wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      height: 100%;
      justify-content: space-around;
      padding: 1.5rem 1rem;
      text-align: center;
      width: 100%;

      @include mq(mobileLarge) {
        gap: 1.5rem;
        width: 100%;
      }
    }

    .submit-btn {
      background-color: $vpag-primary;
      border: none;
      border-radius: 4px;
      font-family: $font-family-bold;
      margin-top: 2rem;
      padding: 0.3rem 0;
      width: 100%;

      &:disabled {
        background-color: gray('700');
        color: gray('900');
        transition: background-color 0.3s ease;
      }
    }

    .input-wrapper {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;

      @include mq(mobileLarge) {
        flex-direction: column;
      }
    }

    input,
    select {
      background-color: gray('900');
      border: none;
      color: $white;
      font-weight: 600;
      height: 35px;
      text-align: center;
      width: 100%;

      &::-webkit-calendar-picker-indicator {
        filter: invert(1);
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}

.complete-report {
  height: 300px;
}

.refuse-action {
  align-items: center;
  background-color: gray('dark');
  color: $white;
  display: flex;
  height: auto;
  max-width: 610px;
  min-height: 80px;
  position: relative;
  width: 85vw;

  @include mq(mobileLarge) {
    padding-bottom: 2rem;
  }

  &--input-wrapper {
    display: flex;
    flex-direction: column;
    padding: 1.3rem 0 1rem 1rem;
    width: 100%;
  }

  textarea {
    background-color: gray('900');
    border: none;
    color: $white;
    font-size: $font-size-md;
    height: 100%;
    margin-bottom: 0.5rem;
    outline: none;
    padding: 1rem 0 0 1rem;
    resize: none;
    width: 70%;

    &::placeholder {
      color: gray('600');
      font-size: $font-size-md;
    }

    @include mq(mobileLarge) {
      width: 95%;
    }
  }

  select {
    background-color: gray('900');
    border: none;
    color: gray('100');
    height: 35px;
    margin: 0.5rem 0;
    outline: none;
    padding: 0 1rem;
    width: 60%;

    &:invalid {
      color: red;
    }

    option {
      color: gray('100');
    }

    @include mq(mobileLarge) {
      width: 95%;
    }
  }

  select:invalid,
  select option[value=''] {
    color: rgba($color: gray('100'), $alpha: 0.5);
  }

  &__submit-btn {
    @extend .modal__submit-btn;
    right: 1.5rem;
    top: 1.8rem;

    @include mq(mobileLarge) {
      bottom: 10px;
      left: 0;
      margin: 0 auto;
      right: 0;
      top: unset;
    }
  }

  &__close-btn {
    @extend .modal__close-btn;

    right: 0.3rem;
    top: 0.3rem;
  }
}

.manage-bank-methods {
  background-color: gray('dark');
  border: none;
  max-width: 95vw;
  overflow-y: auto;
  padding: 1rem 1rem 1.5rem;
  width: 600px;

  @include mq(mobileLarge) {
    padding-bottom: 80px;
  }

  select,
  input {
    background-color: gray('700');
    border: none;
    color: gray('100');
    cursor: pointer;
    font-size: $font-size-md;
    margin-right: 1rem;
    outline: none;
    padding: 0.5rem 1rem;

    &.error {
      border: 1px solid $danger;
    }
  }

  input {
    padding: 0.2rem;
    width: 80px;

    &::placeholder {
      font-size: $font-size-md;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  label {
    color: gray('100');
    display: flex;
    flex-direction: column;
    font-size: $font-size-sm;
    text-transform: uppercase;
  }

  &--submit-btn {
    background-color: $vpag-primary;
    border-radius: 5px;
    border: none;
    font-size: $font-size-md;
    max-width: 120px;
    padding: 0.4rem 1.5rem;

    &:disabled {
      background-color: gray('600');
      color: gray('800');
    }

    &:hover {
      filter: brightness(0.95);
    }

    @include mq(mobileLarge) {
      bottom: 10px;
      position: absolute;
      right: 10px;
    }
  }

  .checkbox-wrapper {
    color: $white;
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    margin: 1rem 0 0 0.5rem;
    width: 100%;
  }

  .input-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 2rem 0 0 0.5rem;

    & > div {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
    }

    @include mq(mobileLarge) {
      flex-direction: column;
    }
  }

  .available-transactions {
    border-bottom: 1px solid gray('600');
    margin-bottom: 2rem;
    padding-bottom: 2rem;

    &:last-of-type {
      border: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

.acam-report-name {
  background-color: gray('700');
  color: gray('100');
  display: flex;
  flex-direction: column;
  height: 100px;
  min-width: 650px;
  overflow: hidden;
  padding: 0;
  width: 30vw;

  &__divider {
    border-bottom: 1px solid rgba($color: gray('100'), $alpha: 0.4);
    height: 1px;
    padding: 0.1rem 0;
    width: 100%;
  }

  input,
  textarea {
    background-color: gray('800');
    border: none;
    color: $white;
    outline: none;
    padding-left: 0.8rem;
  }

  textarea::placeholder {
    font-size: $font-size-md;
  }

  &__submit-btn {
    @extend .modal__submit-btn;

    bottom: 10px;
    color: gray('800');
    right: 10px;
  }
}

.two-fa-modal {
  align-items: center;
  background-color: gray('dark');
  color: $white;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  text-align: center;
  width: 360px;

  p {
    align-items: center;
    display: flex;
    font-size: 18px;
    margin: auto;
  }

  &__submit-btn {
    @extend .vpag-btn;
    border: none;
    border-radius: 5px;
    font-family: $font-family-bold !important;
  }

  &__copy-btn {
    @extend .two-fa-modal__submit-btn;
    border-radius: 20px;
    height: auto !important;
    padding: 0.3rem 1rem;
    width: auto !important;
  }
}

.manual-complete-modal {
  align-items: center;
  background-color: gray('dark');
  color: $white;
  display: flex;
  flex-direction: column;
  height: auto;
  max-width: 610px;
  min-height: 80px;
  position: relative;
  width: 85vw;

  @include mq(mobileLarge) {
    padding-bottom: 2rem;
  }

  &--input-wrapper {
    display: flex;
    flex-direction: column;
    padding: 1.3rem 0 1rem 1rem;
    width: 100%;
  }

  h3 {
    font-size: $font-size-md;
    max-width: 70%;
  }

  input {
    background-color: gray('900');
    border: none;
    color: $white;
    height: 35px;
    margin: 0.5rem 0;
    padding: 0 0.5rem;
    width: 60%;

    &::placeholder {
      color: rgba($color: gray('100'), $alpha: 0.5);
      font-size: $font-size-base;
    }
  }

  select {
    background-color: gray('900');
    border: none;
    color: gray('100');
    height: 35px;
    margin: 0.5rem 0;
    outline: none;
    padding: 0 0.5rem;
    width: 60%;

    &:invalid {
      color: red;
    }

    option {
      color: gray('100');
    }

    @include mq(mobileLarge) {
      width: 95%;
    }
  }

  select:invalid,
  select option[value=''] {
    color: rgba($color: gray('100'), $alpha: 0.5);
  }

  &__submit-btn {
    @extend .modal__submit-btn;
    right: 2.5rem;
    top: 2ric;

    @include mq(mobileLarge) {
      bottom: 10px;
      left: 0;
      margin: 0 auto;
      right: 0;
      top: unset;
    }
  }

  &__close-btn {
    @extend .modal__close-btn;

    right: 0.3rem;
    top: 0.3rem;
  }
}

.issue-modal {
  align-items: center;
  background-color: gray('dark');
  color: $white;
  display: flex;
  flex-direction: column;
  height: auto;
  max-width: 400px;
  min-height: 80px;
  position: relative;
  width: 85vw;

  @include mq(mobileLarge) {
    padding-bottom: 2rem;
  }

  &--input-wrapper {
    display: flex;
    flex-direction: column;
    padding: 1.3rem 0 1rem 1rem;
    width: 100%;
  }

  h3 {
    font-size: $font-size-md;
    max-width: 70%;
  }

  input {
    background-color: gray('900');
    border: none;
    color: $white;
    height: 35px;
    margin: 0.5rem 0;
    padding: 0 0.5rem;
    width: 60%;

    &::placeholder {
      color: rgba($color: gray('100'), $alpha: 0.5);
      font-size: $font-size-base;
    }
  }

  select {
    background-color: gray('900');
    border: none;
    color: gray('100');
    height: 35px;
    margin: 0.5rem 0;
    outline: none;
    padding: 0 0.5rem;
    width: 60%;

    &:invalid {
      color: red;
    }

    option {
      color: gray('100');
    }

    @include mq(mobileLarge) {
      width: 95%;
    }
  }

  select:invalid,
  select option[value=''] {
    color: rgba($color: gray('100'), $alpha: 0.5);
  }

  &__submit-btn {
    @extend .modal__submit-btn;
    right: 2.5rem;
    top: 2ric;

    @include mq(mobileLarge) {
      bottom: 10px;
      left: 0;
      margin: 0 auto;
      right: 0;
      top: unset;
    }
  }

  &__close-btn {
    @extend .modal__close-btn;

    right: 0.3rem;
    top: 0.3rem;
  }
}

.create-customer-bank {
  &__container {
    align-items: center;
    background-color: gray('dark');
    color: $white;
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    width: 360px;

    @include mq(mobileLarge) {
      padding-bottom: 5rem;
      width: 85vw;

      .modal__submit-btn {
        bottom: 10px;
        left: 0;
        margin: 0 auto;
        right: 0;
      }
    }

    .content-wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      height: 100%;
      justify-content: space-around;
      padding: 1.5rem 1rem;
      text-align: center;
      width: 100%;

      @include mq(mobileLarge) {
        gap: 1.5rem;
        width: 100%;
      }
    }

    .submit-btn {
      background-color: $vpag-primary;
      border: none;
      border-radius: 4px;
      font-family: $font-family-bold;
      margin-top: 2rem;
      padding: 0.3rem 0;
      width: 100%;

      &:disabled {
        background-color: gray('700');
        color: gray('900');
        transition: background-color 0.3s ease;
      }
    }

    .input-wrapper {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;

      @include mq(mobileLarge) {
        flex-direction: column;
      }
    }

    input,
    select {
      background-color: gray('900');
      color: $white;
      text-align: center;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}

.bg-dark {
  background-color: gray('900') !important;
}
