/*Theme Dark Indigo Styles*/
$app-primary-vpag-theme: $vpag-primary !default;
$secondary-dark-indigo: #ff4081;

$sidebar-vpag-theme: #191a1b !default;
$sidebar-text-vpag-theme: #a1a1a1 !default;
$sidebar-darken-vpag: darken($sidebar-vpag-theme, 3%);
$sidebar-bg-darken-highlight-vpag: #789b22;

// Links
$link-hover-dark-indigo: $white;

//-Base-scss
.vpag-theme .right-arrow {
  color: $app-primary-vpag-theme;

  &:after {
    color: $app-primary-vpag-theme;
  }
}

//- Bootstrap file Style
.vpag-theme {
  a,
  .card-link,
  .jr-link,
  .jr-link.text-primary {
    color: $vpag-primary;

    &:focus,
    &:hover {
      color: darken($sidebar-vpag-theme, 10%);
    }
  }

  & .text-primary {
    color: $app-primary-vpag-theme !important;
  }

  & .page-link {
    color: $app-primary-vpag-theme;

    &:focus,
    &:hover {
      color: $app-primary-vpag-theme;
    }
  }

  & .page-heading .breadcrumb-item.active {
    color: $app-primary-vpag-theme;
  }

  & .bg-primary {
    background-color: gray('600') !important;
    color: $link-hover-dark-indigo !important;
  }

  & .bg-yellow-green {
    background-color: $vpag-primary-dark;
  }

  & .bg-primary-opacity {
    background-color: rgba(gray('900'), 0.4) !important;
    color: gray('100') !important;
  }

  & .badge-primary {
    background-color: $vpag-primary !important;
    color: $vpag-dark !important;
  }

  & .btn-primary {
    background-color: $app-primary-vpag-theme;
    border-color: $app-primary-vpag-theme;
    color: $link-hover-dark-indigo;

    &:hover,
    &:focus,
    &:active {
      background-color: darken($app-primary-vpag-theme, 5%) !important;
      border-color: darken($app-primary-vpag-theme, 5%) !important;
      color: $link-hover-dark-indigo !important;
    }
  }

  & .bg-primary {
    &.lighten-4 {
      background-color: #c5cae9 !important;
    }

    &.lighten-3 {
      background-color: #9fa8da !important;
    }

    &.lighten-2 {
      background-color: #7986cb !important;
    }

    &.lighten-1 {
      background-color: #5c6bc0 !important;
    }

    &.darken-1 {
      background-color: #3949ab !important;
    }

    &.darken-2 {
      background-color: #303f9f !important;
    }

    &.darken-3 {
      background-color: #283593 !important;
    }

    &.darken-4 {
      background-color: #1a237e !important;
    }

    &.accent-1 {
      background-color: #8c9eff !important;
    }

    &.accent-2 {
      background-color: #536dfe !important;
    }

    &.accent-3 {
      background-color: #3d5afe !important;
    }

    &.accent-4 {
      background-color: #304ffe !important;
    }
  }
}

//Secondary
.vpag-theme {
  .jr-link.text-secondary {
    color: $secondary-dark-indigo;

    &:focus,
    &:hover {
      color: darken($secondary-dark-indigo, 10%) !important;
    }
  }

  & .text-secondary {
    color: $secondary-dark-indigo !important;
  }

  & .bg-secondary,
  & .badge-secondary {
    background-color: $secondary-dark-indigo !important;
    color: $link-hover-dark-indigo !important;
  }

  & .btn-secondary {
    background-color: $secondary-dark-indigo;
    border-color: $secondary-dark-indigo;
    color: $link-hover-dark-indigo;

    &:hover,
    &:focus,
    &:active {
      background-color: darken($secondary-dark-indigo, 5%) !important;
      border-color: darken($secondary-dark-indigo, 5%) !important;
      color: $link-hover-dark-indigo !important;
    }
  }
}

//_header.scss
.vpag-theme .app-main-header {
  background-color: $app-primary-vpag-theme !important;

  & .search-dropdown input {
    @media screen and (max-width: 991px) {
      background-color: $white;
    }
  }
}

//_right-sidebar.scss
.vpag-theme .color-theme-header {
  background-color: $app-primary-vpag-theme;
}

//_sidebar.scss
.vpag-theme .side-nav {
  background-color: $sidebar-vpag-theme !important;
  color: $sidebar-text-vpag-theme !important;
  @include box-shadow(none);

  & .user-profile {
    background-color: $sidebar-darken-vpag;
    position: relative;
    z-index: 2;
    @include box-shadow(none);
  }

  & .customizer {
    border-bottom: 0 none;
  }

  & .user-detail {
    & .user-name {
      color: $link-hover-dark-indigo;
    }
  }
}

.vpag-theme .nav-section {
  & .nav-header {
    color: $sidebar-text-vpag-theme;
  }
}

.vpag-theme .nav-collapse {
  & .nav-collapse-btn {
    color: $sidebar-text-vpag-theme;

    &:focus,
    &:hover {
      background-color: $sidebar-darken-vpag;
      color: $link-hover-dark-indigo;
    }
  }

  &.open {
    background-color: $sidebar-darken-vpag;

    & .nav-collapse-btn {
      background-color: $sidebar-darken-vpag;
      color: $link-hover-dark-indigo;

      &:focus,
      &:hover {
        background-color: $sidebar-darken-vpag;
        color: $link-hover-dark-indigo;
      }
    }
  }
}

.vpag-theme .nav-collapse {
  & .nav-menu-item .nav-menu-link {
    color: $sidebar-text-vpag-theme;

    &:focus,
    &:hover,
    &.active {
      background-color: $sidebar-darken-vpag;
      color: $link-hover-dark-indigo;
    }

    &:after {
      background-color: $white;
    }
  }

  &.open {
    & .nav-menu-item .nav-menu-link.active {
      color: $link-hover-dark-indigo;
    }
  }
}

.vpag-theme .nav-menu-item {
  & .nav-menu-link {
    &:focus,
    &:hover,
    &.active {
      background-color: $sidebar-darken-vpag;
      color: $link-hover-dark-indigo;
    }
  }
}

/*Header top Navbar Styles*/
.vpag-theme .navbar-nav {
  & li {
    &:hover > a,
    &:focus > a,
    &:hover > .nav-link,
    &:focus > .nav-link {
      color: $app-primary-vpag-theme;
    }

    & a,
    & .nav-link {
      color: $sidebar-text-vpag-theme;
    }

    & a:hover,
    & a:focus,
    & .nav-link:hover,
    & .nav-link:focus {
      color: $app-primary-vpag-theme;
    }
  }

  & li.nav-item > a,
  & li.nav-item > .nav-link {
    color: $white;
  }

  & li.nav-item:hover > a,
  & li.nav-item:focus > a,
  & li.nav-item > a:hover,
  & li.nav-item > a:focus,
  & li.nav-item.active > a,
  & li.nav-item:hover > .nav-link,
  & li.nav-item:focus > .nav-link,
  & li.nav-item > .nav-link:hover,
  & li.nav-item > .nav-link:focus,
  & li.nav-item.active > .nav-link {
    color: $secondary-dark-indigo;
  }

  ul.sub-menu {
    & li a.active,
    & li.active > a,
    & li .nav-link.active,
    & li.active > .nav-link {
      color: $app-primary-vpag-theme;
    }
  }

  & .nav-arrow {
    & > a:before,
    & > .nav-link:before {
      color: $sidebar-text-vpag-theme;
    }

    & a:hover,
    & a:focus,
    & .nav-link:hover,
    & .nav-link:focus {
      &:before {
        color: $app-primary-vpag-theme;
      }
    }

    &:hover > a:before,
    &:focus > a:before,
    &.active > a:before,
    &:hover > .nav-link:before,
    &:focus > .nav-link:before,
    &.active > .nav-link:before {
      color: $app-primary-vpag-theme;
    }
  }
}

.vpag-theme .app-top-nav {
  & .navbar-nav {
    & li.nav-item > a,
    & li.nav-item > .nav-link {
      color: $white;
    }

    & li.nav-item:hover > a,
    & li.nav-item:focus > a,
    & li.nav-item > a:hover,
    & li.nav-item > a:focus,
    & li.nav-item.active > a,
    & li.nav-item:hover > .nav-link,
    & li.nav-item:focus > .nav-link,
    & li.nav-item > .nav-link:hover,
    & li.nav-item > .nav-link:focus,
    & li.nav-item.active > .nav-link {
      color: $secondary-dark-indigo;
    }
  }
}

.vpag-theme .app-header-horizontal {
  & .app-main-header-top {
    @include box-shadow(none !important);
  }
}

.vpag-theme .header-notifications .app-notification {
  & .jr-list-link {
    @include hover-focus-active {
      border-color: $app-primary-vpag-theme;
      color: $app-primary-vpag-theme;
    }
  }
}

//_app-module.scss
.vpag-theme .module-nav {
  & li {
    & a.active {
      border-color: $app-primary-vpag-theme;
    }

    & .jr-link.active {
      color: $app-primary-vpag-theme;
    }
  }
}

//_calendar.scss
.vpag-theme .rbc-event {
  background-color: $app-primary-vpag-theme;
}

.vpag-theme .rbc-event.rbc-selected {
  background-color: darken($app-primary-vpag-theme, 10%);
}

.vpag-theme .rbc-slot-selection {
  background-color: rgba($app-primary-vpag-theme, 0.7);
}

.vpag-theme .rbc-toolbar button:active,
.vpag-theme .rbc-toolbar button.rbc-active {
  background-color: rgba($app-primary-vpag-theme, 0.9);
  border-color: $app-primary-vpag-theme;
}

.vpag-theme .rbc-toolbar button:active:hover,
.vpag-theme .rbc-toolbar button.rbc-active:hover,
.vpag-theme .rbc-toolbar button:active:focus,
.vpag-theme .rbc-toolbar button.rbc-active:focus {
  background-color: rgba($app-primary-vpag-theme, 0.9);
  border-color: $app-primary-vpag-theme;
}

.vpag-theme .rbc-toolbar button:focus {
  background-color: rgba($app-primary-vpag-theme, 0.9);
  border-color: $app-primary-vpag-theme;
}

.vpag-theme .rbc-toolbar button:hover {
  background-color: rgba($app-primary-vpag-theme, 0.9);
  border-color: $app-primary-vpag-theme;
}

//_chat.scss
.vpag-theme .chat-sidenav-title {
  color: $app-primary-vpag-theme;
}

.vpag-theme .chat-user-item {
  &.active,
  &:hover {
    background-color: lighten($app-primary-vpag-theme, 45%);
  }
}

//_dashboard.scss
.vpag-theme .contact-list {
  & i {
    color: $app-primary-vpag-theme;
  }
}

//The link which when clicked opens the collapsable ChatUserList
.vpag-theme .Collapsible__trigger {
  background: $app-primary-vpag-theme;
}

.vpag-theme .categories-list {
  & li a:focus,
  & li a:hover,
  & li.active a {
    color: $app-primary-vpag-theme;
  }
}

//_login.scss

.vpag-theme .login-content .form-control {
  &:focus {
    border-color: $app-primary-vpag-theme;
  }
}

//_portfolio.scss
.vpag-theme .filter-with-bg-color ul li {
  .jr-link {
    border-color: $app-primary-vpag-theme;

    &:hover,
    &:focus,
    &.active {
      background-color: $app-primary-vpag-theme;
    }
  }
}

//_card.scss
.vpag-theme .profile-intro {
  & .icon {
    color: $app-primary-vpag-theme;
  }
}

.vpag-theme .social-link {
  & li.active a,
  & li a:hover,
  & li a:focus {
    color: $secondary-dark-indigo;
  }
}

//_tables.scss
.vpag-theme .actions {
  color: $secondary-dark-indigo;
}

.vpag-theme .table-hover tbody tr:hover {
  background-color: rgba(75, 82, 88, 0.3);
  color: $white;
}

//Border Color

.vpag-theme .border-primary {
  border-color: $app-primary-vpag-theme !important;
}

// login page content

.vpag-theme .app-logo-content {
  background-color: $vpag-dark;
}

.vpag-theme .app-social-block {
  & .social-link button {
    border: solid 1px $app-primary-vpag-theme;
    color: $app-primary-vpag-theme;

    &:hover,
    &:focus {
      background-color: $app-primary-vpag-theme;
      color: $white;
    }
  }
}

/*Button Group Styles*/
.vpag-theme .btn-group,
.vpag-theme .btn-group-vertical {
  > .jr-btn {
    &.active {
      background-color: $app-primary-vpag-theme;
      border-color: $app-primary-vpag-theme;
    }
  }

  > .jr-flat-btn {
    background-color: transparent;
    border-color: transparent;

    &.active {
      background-color: transparent;
      color: $app-primary-vpag-theme;

      &:hover,
      &:focus {
        background-color: rgba($app-primary-vpag-theme, 0.12);
        color: $app-primary-vpag-theme;
      }
    }
  }
}

/*Classic Dashboard Styles*/
.vpag-theme .jr-fillchart-btn-close,
.vpag-theme .jr-onchart .jr-badge-up,
.vpag-theme .jr-task-list-item:hover .jr-text-hover {
  color: $app-primary-vpag-theme;
}

.vpag-theme .jr-entry-title:before {
  background-color: $app-primary-vpag-theme;
}

.vpag-theme .jr-card-ticketlist {
  & .jr-task-list-item:hover .jr-task-item-title,
  .jr-link {
    color: $app-primary-vpag-theme;
  }
}

.vpag-theme .slick-dots li.slick-active button:before {
  border-color: $app-primary-vpag-theme;
}

//Nav Styles
.vpag-theme .nav-pills .nav-link.active,
.vpag-theme .nav-pills .show > .nav-link {
  background-color: $app-primary-vpag-theme;
  color: $white !important;
}

// Gradient Color Class
.vpag-theme .bg-gradient-primary {
  @include gradient-directional(
    $app-primary-vpag-theme,
    lighten($app-primary-vpag-theme, 16%),
    0deg
  );
}

.vpag-theme .bg-gradient-primary-x {
  @include gradient-x(
    darken($app-primary-vpag-theme, 10%),
    lighten($app-primary-vpag-theme, 16%),
    70%,
    100%
  );
}

//Profile style
.vpag-theme .jr-profile-banner {
  background-color: $vpag-dark;
  color: $white;
  font-weight: bold;

  & .jr-link {
    color: $vpag-primary;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: $vpag-primary-dark;
    }
  }

  & .jr-subtitle {
    color: gray('100');
  }
}

.jr-card-profile,
.jr-card-profile-sm {
  background-color: gray('600');
  color: $white;
}

.jr-card-title {
  color: $white;
  font-size: 18px !important;
  font-weight: 300;
}

.vpag-theme .dropdown-item {
  &.active,
  &:active {
    background-color: $app-primary-vpag-theme;
    color: $white;
  }

  &[class*='text-'] {
    &.active,
    &:active {
      background-color: transparent;
    }
  }
}

.vpag-error-light {
  color: $white;
}

.text-gray-light {
  color: gray('100') !important;
}

.text-vpag-primary {
  color: $vpag-primary;
}

.table-gray {
  background-color: gray('600');
  color: $white;
}

.text-light-gray {
  color: gray('100');
}

.selected {
  border: 2px solid $vpag-primary;
  border-radius: 50%;
}

.status-tag {
  align-items: center;
  align-self: center;
  display: flex;
  font: 400 14px $font-family-bold;
  height: 20px;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  width: 100px;

  &.small {
    width: 35px;
  }

  &.pending,
  &.generated,
  &.PENDING,
  &.created {
    background-color: $vpag-info;
  }

  &.completed,
  &.paid-out,
  &.active,
  &.approved,
  &.validated,
  &.MANUAL_APPROVAL,
  &.VALID {
    background-color: $vpag-primary;
    color: gray('900');
  }

  &.cancelled,
  &.refused,
  &.REFUSED,
  &.MANUAL_REPROVAL,
  &.INVALID,
  &.error,
  &.failed {
    background-color: $vpag-danger;
  }

  &.inactive {
    background-color: rgba(gray('700'), 0.5);
    color: rgba(gray('100'), 0.7);
  }

  &.received {
    background-color: gray('100');
    color: gray('700');
  }

  &.sent,
  &.inanalysis {
    background-color: $vpag-pink;
  }

  &.disabled {
    background-color: rgba(gray('700'), 0.5);
  }
}

.status-tag-minimal {
  min-width: 50px;
  text-transform: uppercase;

  &.active {
    color: $vpag-primary;
  }

  &.inactive {
    color: gray('100');
  }
}

.monitoring-tag {
  background-color: $vpag-pink;
}

.brightness-80 {
  filter: brightness(0.8);
}

.action-btn {
  border: none;
  border-radius: 4px;
  color: $white;
  font-weight: 600;
  margin-right: 0.8rem;
  opacity: 0.9;
  padding: 5px 30px;

  &:hover {
    opacity: 1;
  }

  &:disabled {
    background-color: gray('700');
    color: gray('100');
    cursor: not-allowed;
  }

  &__approve,
  &__enable {
    background-color: $vpag-sucess;
    border: none;
    border-radius: 4px;
    color: $white;
    font-weight: 600;
    padding: 5px 30px;
  }

  &__refuse,
  &__delete {
    background-color: $vpag-danger;
  }

  &__disable {
    background-color: $vpag-inactive;
  }

  &__create {
    background-color: $vpag-primary;
    color: gray('800');
  }
}

.low-opacity {
  background: rgba(50, 55, 58, 0.2) !important;
}

.bg-black-10 {
  background-color: rgba($black, 0.1);
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.icon-refresh {
  color: $vpag-primary;
  cursor: pointer;
  margin-right: 10px;

  &:hover {
    animation: rotate 0.3s linear forwards;
  }
}
