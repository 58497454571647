/*Bottom Navigation Styles*/
.bottom-navigation {
  button {
    min-width: 10px !important;
  }
}

.vpag-btn-navigation {
  background-color: $vpag-primary !important;
  font-family: 'Inter';
  font-size: 16px !important;
  font-weight: 300;
  height: 56px;
  text-transform: capitalize !important;
  width: 180px;
}
