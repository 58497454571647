/*Button Styles*/
button {
  cursor: pointer;
  outline: 0 none !important;
}

.btn,
.jr-btn {
  @include border-radius($border-radius-sm !important);
  cursor: pointer;
  font-size: $btn-font-size !important;
  font-weight: $btn-font-weight !important;
  line-height: $btn-line-height !important;
  min-height: 10px !important;
  min-width: 10px !important;
  position: relative;
  text-transform: capitalize !important;
}

.btn {
  &:focus,
  &:active {
    @include box-shadow(none !important);
    outline: 0 none !important;
  }
}

.text-uppercase {
  text-transform: uppercase !important;
}

.jr-btn,
.jr-fab-btn,
.btn {
  margin-bottom: 6px;

  &:not(:last-child) {
    margin-right: 15px;
  }
}

.jr-fab-btn {
  @extend %size-50;
  min-height: 10px !important;
}

.jr-btn {
  padding: $btn-padding !important;

  & i + span,
  & span + i {
    margin-left: 6px;
  }
}

.jr-btn-group {
  margin: 0 -10px;

  &:not(:last-child) {
    margin-bottom: 14px;
  }

  & .jr-btn,
  & .jr-fab-btn {
    margin: 0 10px 16px;
  }
}

.jr-btn-rounded {
  @include border-radius(50px !important);
}

.jr-btn-lg {
  padding: $btn-padding-lg !important;
}

.jr-btn-sm {
  padding: $btn-padding-sm !important;
}

.jr-btn-xs {
  padding: $btn-padding-xs !important;
}

// Fab Button
.jr-btn-fab-lg {
  @extend %size-60;
}

.jr-btn-fab-sm {
  @extend %size-40;
}

.jr-btn-fab-xs {
  @extend %size-30;
}

.jr-btn-fab-lg,
.jr-btn-lg {
  font-size: $btn-font-size-lg !important;

  & i {
    font-size: ($btn-font-size-lg + 0.125rem) !important;
  }
}

.jr-btn-fab-sm,
.jr-btn-sm {
  font-size: $btn-font-size-sm !important;

  & i {
    font-size: $btn-font-size-sm !important;
  }
}

.jr-btn-fab-xs,
.jr-btn-xs {
  font-size: $btn-font-size-xs !important;

  & i {
    font-size: $btn-font-size-xs !important;
  }
}

.complex-btn-wrapper {
  @include display-flex();
  min-width: 200px;
  width: 100%;
}

.complex-btn {
  height: 200px;
  overflow: hidden;
  position: relative;
  @media screen and (max-width: 767px) {
    height: 100px !important;
    width: 100% !important;
  }

  &:hover {
    z-index: 1;

    & .img-btn-overlay {
      opacity: 0.15;
    }

    & .img-marked {
      opacity: 0;
    }

    & .img-title {
      border: 4px solid currentColor;
    }
  }

  .img-btn {
    @include align-items(center);
    @include justify-content(center);
    bottom: 0;
    color: $white;
    display: flex;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .img-src {
    background-position: center 40%;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .img-btn-overlay {
    @include transition(all 0.5s $transition-ease);
    background: $black;
    bottom: 0;
    left: 0;
    opacity: 0.4;
    position: absolute;
    right: 0;
    top: 0;
  }

  .img-title {
    padding: 16px 32px 14px;
    position: relative;
  }

  .img-marked {
    @include transition(all 0.5s $transition-ease);
    background: $white;
    bottom: -2px;
    height: 3px;
    left: calc(50% - 9px);
    position: absolute;
    width: 18px;
  }
}

.btn-group-mins {
  margin: 0 -5px;

  & .btn,
  & .jr-btn {
    margin: 0 5px 5px;
  }
}

//buttons in label

.jr-btn.jr-btn-label i {
  background-color: rgba($white, 0.2);
  height: 100%;
  line-height: 28px;
  width: $size-40;
}

.jr-btn.jr-btn-label.left i {
  @include align-items(center);
  @include display-flex();
  @include justify-content(center);
  bottom: 0;
  left: 0;
  line-height: 1;
  position: absolute;
  top: 0;
  z-index: 1;
}

.jr-btn.jr-btn-label.right i {
  @include align-items(center);
  @include display-flex();
  @include justify-content(center);
  bottom: 0;
  line-height: 1;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.jr-btn.jr-btn-label.left i + span {
  margin-left: $size-30;
}

.jr-btn.jr-btn-label.right i + span {
  margin-left: 0;
  margin-right: $size-30;
}

.jr-btn.jr-btn-label.jr-btn-lg i {
  line-height: 54px;
}

.jr-btn.jr-btn-label.jr-btn-sm i {
  line-height: 32px;
}

.jr-btn.jr-btn-label.jr-btn-xs i {
  line-height: 24px;
  width: $size-30;
}

.jr-link,
.card-link {
  cursor: pointer;
  color: $app-primary;

  &:hover,
  &:focus {
    color: darken($app-primary, 10%);
  }
}

.vpag-btn,
.vpag-btn-crud {
  background-color: $vpag-primary !important;
  border: none;
  border-radius: 4px;
  font-weight: 300;
  font: 14px $font-family-bold !important;
  max-height: 39px;
  padding: 0.5rem 1.5rem !important;
  text-transform: capitalize !important;
  transition: 0.2s;
  width: auto;

  .uppercase-fl {
    display: block;
    text-transform: lowercase !important;

    &::first-letter {
      text-transform: uppercase !important;
    }
  }

  &:disabled {
    background-color: gray('700') !important;
    color: gray('dark') !important;
    pointer-events: unset !important;
  }

  &:hover {
    filter: brightness(0.95);
  }
}

.vpag-btn-crud {
  margin-top: 0 !important;
}

.widget-btn {
  background-color: $vpag-primary !important;
  color: gray('700') !important;
  font: 12px 'Inter' !important;
  height: 30px;
  padding: 14px;
  text-transform: capitalize !important;
  width: 100px;

  &:hover {
    filter: brightness(0.95);
  }
}

.circular-button {
  background-color: gray('700');
  border: none;
  border-radius: 50%;
  color: $white;
  font-size: 1rem;
  height: 50px;
  margin-right: 2rem;
  width: 50px;
}

.notes-btn-wrapper {
  display: flex;
  position: absolute;
  right: 1rem;
  top: 10px;
}

.notes-btn {
  background-color: transparent;
  border: none;
  padding: 0 2px;

  &:hover {
    transform: scale(1.1);
  }

  svg {
    width: 16px;
  }
}

.notes-collapse-btn {
  bottom: 5px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 10px;
}

.recent-collapse-btn {
  @extend .notes-collapse-btn;

  bottom: 0;
  right: auto;
}

.delete-button {
  background-color: transparent;
  border: none;
  transition: 0.2s transform ease;

  path {
    fill: gray('700');
  }

  &:hover {
    transform: scale(1.1);

    path {
      fill: $danger;
    }
  }
}

.header-button-container {
  align-items: center;
  display: flex;
  gap: 0.3rem;
  margin-left: 1rem;

  span {
    color: $white;
    font: $font-size-sm $font-family-bold;
    opacity: 0;
    text-transform: uppercase;
    transition: 0.3s opacity ease;
  }

  &:hover {
    span {
      opacity: 1;
      width: auto;
    }
  }
}

.header-action-button {
  background-color: gray('700');
  border-radius: 5px;
  border: none;
  padding: 2px 4px;
  position: relative;
  transition: 0.3s transform ease;

  path {
    fill: gray('100');
  }

  &:hover {
    background-color: $danger;

    &.success {
      background-color: $vpag-primary;

      path {
        fill: gray('900');
      }
    }

    &.retry {
      background-color: $vpag-info;
    }

    &.refund {
      background-color: gray('900');
    }
  }
  &:disabled {
    background-color: gray('900');
  }
}

.widget-tab-inactive,
.widget-tab-active {
  color: gray('100');
  cursor: pointer;
  display: grid;
  font: 12px $font-family-bold;
  height: 100%;
  padding: 0 2rem;
  place-content: center;
  position: relative;
  text-transform: uppercase;
  top: 0;
  width: auto;
}

.widget-tab-active {
  background-color: rgba($black, 0.5);
  color: $vpag-primary;
  filter: brightness(0.9);

  &::after {
    background-color: $vpag-primary;
    bottom: 0;
    content: '';
    height: 3px;
    position: absolute;
    width: 100%;
  }
}

.form-button {
  align-items: center;
  background-color: $vpag-primary;
  border: none;
  color: gray('700') !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-width: 200px;
  padding: 0.6rem 1rem;
  text-decoration: none !important;
  text-transform: capitalize;
  transition: 0.2s;
  width: 60% !important;

  &:hover {
    filter: brightness(0.9);
  }
}

.remove-pep-btn {
  background-color: $danger;
  border: none;
  border-radius: 5px;
  color: $white;
  font-family: $font-family-bold;
  opacity: 0.9;
  padding: 0.3rem 1rem;

  &:hover {
    opacity: 1;
  }
}

.text-button {
  background-color: transparent;
  border: none;
  color: $vpag-primary;
  font-family: $font-family-bold;
  text-decoration: underline;

  &:disabled {
    color: gray('700');
    cursor: not-allowed;
  }
}

.default-button {
  background-color: $vpag-primary;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: $font-family-bold;
  height: 30px;
  margin-left: 0.8rem;

  &:disabled {
    background: rgba(225, 217, 205, 0.3);
    color: gray('900');
    cursor: not-allowed;
  }
}
