/*Blockquotes Styles*/
blockquote {
  font-size: $font-size-base !important;
  padding: 10px 0 10px 20px;

  .blockquote-footer {
    font-size: $font-size-base !important;
  }
}

.blockquote {
  border-left: 5px solid $gray-200;
}

.blockquote-reverse {
  border-left: 0 none;
  border-right: 5px solid $gray-200;
  padding-left: 0;
  padding-right: 20px;
  text-align: right;
}
