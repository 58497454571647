/*Pricing Tables Styles*/
.pt-classic,
.pt-default,
.pt-dark,
.pt-circle {
  & .package {
    -ms-transform: scale(0.95); // IE9 only
    -o-transform: scale(0.95);
    -webkit-transform: scale(0.95);
    @include border-radius(10px);
    @include transition(all 0.5s ease-in-out);
    border: 0 none;
    overflow: hidden;
    padding: 0;
    position: relative;
    transform: scale(0.95);

    &.highlight {
      -ms-transform: scale(1); // IE9 only
      -o-transform: scale(1);
      -webkit-transform: scale(1);
      transform: scale(1);
      z-index: 2;
    }
  }

  & .package-header {
    padding: 35px;
    text-align: center;

    & > .price {
      display: inline-block;
      font-size: 36px;
      font-weight: 700;
      margin-bottom: 12px;
      margin-left: 0;

      & > i {
        display: inline-block;
        font-weight: bold;
        margin-right: 6px;
      }
    }

    & h5 {
      color: inherit;
    }
  }

  & .package-items {
    font-size: 14px;
    list-style: none;
    margin: 0;
    padding: 50px 50px 120px;

    & li {
      @include clearfix();
      line-height: inherit;
      margin-bottom: 16px;

      & i {
        float: left;
        line-height: inherit;
        margin-right: 16px;
        min-width: 20px;
      }

      & span {
        display: block;
        overflow: hidden;
      }
    }
  }

  & .package-footer {
    bottom: 0;
    left: 0;
    padding: 40px 50px;
    position: absolute;
    right: 0;
    text-align: center;

    & .btn {
      border: 0 none;
      color: inherit;
      padding: 10px 30px;
    }
  }
}

.pt-classic .package {
  @include border-radius(0);
  border: 0 none;
}

.pt-dark {
  & .package {
    &:hover {
      @include border-radius(20px);
    }
  }
}

.pt-circle {
  & .package-header {
    @include align-items(center);
    @include border-radius(50%);
    height: 224px;
    margin: 40px auto 0;
    width: 224px;

    & .price {
      margin-top: 30px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .pt-classic,
  .pt-default,
  .pt-dark,
  .pt-circle {
    & .package-header {
      padding: 30px 20px;
    }

    & .package-items {
      padding: 30px 20px 120px;
    }
  }

  .pt-circle {
    & .package-header {
      height: 204px;
      width: 204px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .pt-classic,
  .pt-default,
  .pt-dark,
  .pt-circle {
    & .package {
      -ms-transform: scale(1);
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}
