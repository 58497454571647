// Currencies Widget styles

.currency-default-btn {
  background-color: transparent;
  border: none;
  color: $vpag-primary;
  font-size: $font-size-lg;
  font-weight: 700;

  &.default-wallet {
    background: linear-gradient($vpag-primary, $vpag-primary) bottom no-repeat;
    background-size: 90% 2px;
  }
}

.merchant-wallet-container {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 1.5rem 1.5rem 0;
  width: 100%;
}

.merchant-wallet-selection-row {
  align-items: center;
  display: grid;
  grid-template-columns: 0.2fr 0.5fr 1fr 1fr;
  justify-items: center;
  margin-bottom: 5px;

  .input-number {
    background-color: gray('700');
    border: none;
    color: $white;
    font-weight: 600;
    height: 30px;
    max-width: 90px;
    padding-right: 5px;
    text-align: right;
    width: 90%;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.merchant-wallet-iso {
  align-items: center;
  background-color: $vpag-primary;
  border-radius: 50%;
  color: gray('800');
  display: flex;
  font-size: 18px;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  min-width: 40px;
  padding-top: 3px;
  width: 40px;
}

.default-merchant-wallet-iso {
  color: $vpag-primary;
  position: absolute;
  top: -15px;
}

.currency-selection-btn {
  align-items: center;
  background-color: $vpag-primary;
  border: none;
  border-radius: 50%;
  color: gray('900');
  display: flex;
  font-size: 18px;
  font-weight: 700;
  height: 40px;
  justify-content: center;
  margin-right: 0.5rem;
  padding-top: 4px;
  width: 40px;

  &.inactive {
    background-color: gray('700');
  }
}

.kyc-validations {
  align-items: center;
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 0.5fr 0.5fr;
  justify-items: start;
  padding: 0.5rem 0;
  row-gap: 0.8rem;
  width: 100%;

  & > :last-child {
    justify-self: end;
  }

  & > :nth-child(2) {
    justify-self: start;
  }

  .validate-btn {
    background-color: $vpag-primary;
    border: none;
    border-radius: 5px;
    height: 23px;
    padding: 0 0.5rem;

    &:disabled {
      display: none;
    }
  }

  .manual-request-btn {
    align-items: center;
    background-color: transparent;
    border: none;
    border-radius: 35px;
    display: flex;
    height: 30px;
    justify-content: center;
    width: 30px;
    text-decoration: none;

    &:hover {
      background-color: rgba(gray('700'), 0.3);
    }
  }

  .external-link {
    &:hover,
    &:focus,
    &:active {
      color: $vpag-primary;
    }
  }

  @include mq(mobile) {
    grid-template-columns: repeat(3, 1fr);

    svg {
      display: none;
    }
  }
}

.kyc-validaion-resume {
  column-gap: 10%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  padding: 1rem 0;
  width: 100%;
}

.sof-document-resume {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  width: 100%;

  .image-preview {
    max-height: 242px;
    max-width: 395px;
  }

  @include mq(tablet) {
    .image-preview {
      max-height: 150px;
      max-width: 150px;
    }
  }

  &--fields {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 50%;

    @include mq(tablet) {
      padding: 2rem 0 !important;
    }
  }

  input {
    background-color: rgba(gray('700'), 0.5);
    border: none;
    color: $white;
    font-weight: 600;
    height: 30px;
    width: 150px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.bank-associated-methods {
  width: 100%;

  .method-wrapper {
    @extend .gradient-gray-hover;
    align-items: start;
    column-gap: 1rem;
    display: grid;
    grid-template-columns: 0.8fr repeat(3, 1fr) 0.3fr;
    justify-items: center;
    justify-items: start;
    overflow-x: auto;
    padding: 1rem 0;
    width: 100%;

    strong {
      color: $white;
    }

    @include mq(mobileLarge) {
      column-gap: 2rem;
      grid-template-columns: repeat(5, 1fr);
    }
  }
}

.bank-integration-info {
  column-gap: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  row-gap: 0.8rem;
  width: 100%;

  @include mq(tablet) {
    grid-template-columns: 1fr;
  }
}

.merchant-limits {
  &--list {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1.5rem;

    @include mq(mobileLarge) {
      align-items: center;
      flex-direction: column;
    }
  }
}

.roles {
  &--list-wrapper {
    display: flex;
    flex-direction: column;
  }

  &--list-item {
    align-items: start;
    display: grid;
    gap: 2rem;
    grid-template-columns: 0.8fr 2fr;
    padding: 1rem 0;

    & > *:nth-child(even) {
      justify-self: end;
    }

    &:not(:last-child) {
      border-bottom: 1px solid rgba(gray('100'), 0.1);
    }

    svg {
      max-width: 25px;
    }
  }

  &--available-roles {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  &--role-card {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin: 0.5rem 1rem;
    max-width: 70px;
    text-align: center;

    span {
      font: $font-size-sm $font-family-light;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 80px;
    }
  }
}

.associated-merchants {
  &--list-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    padding-bottom: 2rem;

    @include mq(tablet) {
      align-items: center;
      flex-direction: column;
    }
  }

  &--merchant-list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    @include mq(tablet) {
      justify-content: center;
      max-width: none;
    }
  }

  &--merchant-card {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
    text-align: center;
    width: 60px;

    span {
      font: $font-size-sm $font-family-light;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 60px;
    }
  }
}

.merchant-fees {
  &__list-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 1.5rem;
    width: 100%;
  }

  &__list-item {
    align-items: center;
    display: flex;
    padding-top: 0.5rem;
  }

  &__input-wrapper {
    color: gray('100');
    display: flex;
    flex-direction: column;
    font-size: $font-size-sm;
    padding-bottom: 1rem;
  }

  &__input-field {
    background-color: gray('700');
    border: none;
    color: $white;
    font-size: 16px;
    font-weight: 700;
    height: 35px;
    margin-right: 0.5rem;
    padding: 0.2rem 0;
    text-align: center;
    width: 80px;

    &::placeholder {
      color: gray('100');
      font-size: 14px;
    }

    &::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &.error {
      border: 1px solid $danger;

      &:focus {
        outline: 1px solid $danger !important;
      }
    }
  }
}

.customer-merchant-emails {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  width: 100%;

  &__item-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0;
    width: 100%;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(gray('100'), 0.4);
    }
  }

  &__copy-btn {
    background-color: transparent;
    border: none;
    margin-left: 0.4rem;
  }
}

.roles-summary {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);

  @include mq(laptop) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq(mobile) {
    grid-template-columns: 1fr;
  }
}

.recent-activity {
  padding: 0.8rem 0;
  position: relative;

  &__avatar {
    background-color: gray('800') !important;
  }

  &__expand-btn {
    color: $white;
    position: absolute;
    right: 0;
    top: -35px;
  }
}
