/* Merchant credentials styles  */

.credentials {
  &__container {
    align-items: center;
    background-color: gray('600');
    border-radius: 10px;
    display: grid;
    flex: 1;
    grid-template-columns: 40% 1% 60%;
    justify-items: center;
    min-height: 100%;
    width: 100%;

    @media screen and (max-width: 950px) {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }

  &__divider {
    border-left: 1px solid rgba(gray('100'), 0.6);
    display: block;
    min-height: 70%;

    @media screen and (max-width: 950px) {
      border: none;
      border-bottom: 1px solid rgba(gray('100'), 0.6);
      min-width: 50%;
    }
  }

  div {
    background-color: gray('900');
  }

  &__section {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 3rem;

    h1 {
      color: $white;
    }

    label {
      color: gray('100');
      display: flex;
      flex-direction: column;
      font-size: $font-size-sm;
      position: relative;
      text-transform: uppercase;
    }

    input {
      background-color: gray('700');
      border: none;
      color: gray('100');
      height: 35px;
      margin-bottom: 1rem;
      outline: none;
      padding-left: 10px;
      width: 280px;
    }

    p {
      max-width: 380px;
      text-align: center;
    }

    @media screen and (max-width: 950px) {
      gap: 1.5rem;
      margin: 1rem;

      &:first-of-type {
        margin-bottom: 2rem;
      }
    }
  }

  &__input-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &__copy-btn {
    background-color: transparent;
    border: none;
    bottom: 1.2rem;
    position: absolute;
    right: -2.8rem;

    svg {
      fill: $white;
      width: 20px;
      transition: 0.2s transform;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  &__submit-btn {
    background-color: $vpag-primary;
    border-radius: 5px;
    border: none;
    font-size: $font-size-md;
    height: 40px;
    max-width: 300px;
    min-width: 200px;
    width: 100%;
  }
}
