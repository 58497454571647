.dashboard-grid {
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
  justify-content: flex-start;

  @include mq(laptop) {
    justify-content: center;
  }

  .arrow-up {
    border-bottom: 7px solid $vpag-primary;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    height: 0px;
    margin: 0 0 3px 4px;
    width: 0px;
  }

  .arrow-down {
    @extend .arrow-up;
    border-bottom: none;
    border-top: 7px solid $vpag-danger;
  }
}

.dashboard-widget-title {
  color: gray('100');
  font: $font-size-sm $font-family-secondary;
  text-transform: uppercase;
}

.widget-wrapper-horizontal {
  column-gap: 10px;
  display: grid;
  grid-template-columns: repeat(2, 50%);

  @include mq(laptop) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.widget-wrapper-row {
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
  height: 100%;
  min-width: 100%;
}

.widget-wrapper-triple {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.widget-wrapper-vertical {
  display: grid;
  grid-template-columns: 100%;
  row-gap: 10px;
}

.widget-sm {
  align-items: center;
  background-color: gray('600');
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 5%;
  height: 145px;
  justify-content: flex-start;
  min-height: 130px;
  padding: 6px;
  position: relative;
  text-align: center;
  width: 240px;

  h1 {
    color: $white;
  }

  &.expand {
    height: auto;
  }

  & .collapse-button {
    background-color: transparent;
    border: none;
    bottom: 2px;
    position: absolute;
    right: 2px;

    svg {
      color: $white;
    }
  }
}

.widget-refresh-btn {
  background-color: transparent;
  border: none;

  svg {
    color: $white;
  }

  &.gray {
    svg {
      color: gray('600');
    }
  }
}

.widget-md {
  background-color: gray('600');
  border-radius: 4px;
  color: $white;
  padding: 5px;
  text-align: center;
}

.widget-lg {
  @extend .widget-sm;
  width: 495px;

  @include mq(mobile) {
    max-width: 495px;
    width: 100%;
  }
}

.triple-widget-wrapper {
  column-gap: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.chart-widget {
  background-color: gray('100');
  border-radius: 4px;
  color: gray('600');
  display: flex;
  flex-direction: column;
  max-height: 330px;
  min-height: 300px;
  padding: 5px 10px 10px;
  text-align: center;
  width: 240px;

  .chart-wrapper {
    align-items: flex-end;
    background-color: rgba(0, 0, 0, 0.08);
    display: flex;
    flex: 1;
    justify-content: center;
    position: relative;

    .recharts-surface {
      overflow: visible !important;
    }
  }

  &__title {
    color: gray('700');
    font: 700 $font-size-sm $font-family-secondary;
    text-transform: uppercase;
  }
}

.widget-pending-transactions {
  &__title-wrapper {
    align-items: center;
    align-self: start;
    display: flex;
    gap: 0.5rem;
    justify-content: center;

    p {
      margin: 0;
    }
  }

  &__select {
    background-color: gray('700');
    border: none;
    color: $white;
    cursor: pointer;
    height: 20px;
    outline: none;
    position: absolute;
    right: 5px;
  }
}

.widget-currency-select {
  background-color: gray('700');
  border: none;
  color: $white;
  height: 20px;
  outline: none;
  position: absolute;
  right: 5px;
}

.profit-widget {
  align-items: center;
  display: flex;
  flex-direction: column;

  p {
    color: gray('100');
    font-size: $font-size-sm;
    margin: 0;
    text-transform: uppercase;
  }

  span {
    color: $white;
    font-weight: 600;
    margin-left: 5px;
  }
}

.report-summary {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-around;
  overflow: hidden;
  padding: 0 1rem;
  width: 100%;

  @include mq(mobile) {
    display: flex;
    flex-direction: column;
    width: 240px;
  }

  &__list-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 80%;
    padding: 1rem 0;
  }

  &__list-item {
    display: grid;
    grid-template-columns: 0.5fr 1fr 2fr 0.5fr;
  }
}

.width-240 {
  width: 240px;
}
