//Place Holders
%jr-menu-icon {
  .menu-icon {
    background-color: $vpag-dark;
    color: inherit;
    display: block;
    height: 2px;
    min-height: 2px;
    position: relative;
    width: 70%;
    @include transition(all 0.4s ease);

    &:after,
    &:before {
      background-color: inherit;
      color: inherit;
      display: block;
      height: 2px;
      left: 0;
      min-height: 2px;
      position: absolute;
      content: '';
      @include transition(all 0.4s ease);
    }

    &:after {
      bottom: -6px;
      width: 120%;
    }

    &:before {
      top: -6px;
      width: 130%;
    }
  }

  &:hover {
    & .menu-icon {
      width: 100%;

      &:after,
      &:before {
        width: 100%;
      }
    }
  }
}

%size-50 {
  height: $size-50 !important;
  line-height: $size-50;
  width: $size-50 !important;
}

%size-60 {
  height: $size-60 !important;
  line-height: $size-60;
  width: $size-60 !important;
}

%size-70 {
  height: $size-70 !important;
  line-height: $size-70;
  width: $size-70 !important;
}

%size-80 {
  height: $size-80 !important;
  line-height: $size-80;
  width: $size-80 !important;
}

%size-90 {
  height: $size-90 !important;
  line-height: $size-90;
  width: $size-90 !important;
}

%size-100 {
  height: $size-100 !important;
  line-height: $size-100;
  width: $size-100 !important;
}

%size-36 {
  height: ($size-30 + 6) !important;
  line-height: ($size-30 + 6);
  width: ($size-30 + 6) !important;
}

%size-30 {
  height: $size-30 !important;
  line-height: $size-30;
  width: $size-30 !important;
}

%size-20 {
  height: $size-20 !important;
  line-height: $size-20;
  width: $size-20 !important;
}

%size-10 {
  height: $size-10 !important;
  line-height: $size-10;
  width: $size-10 !important;
}

%size-8 {
  height: ($size-10 - 2) !important;
  line-height: ($size-10 - 2);
  width: ($size-10 - 2) !important;
}

%size-40 {
  height: $size-40 !important;
  line-height: $size-40;
  width: $size-40 !important;
}

%size-120 {
  height: $size-120 !important;
  line-height: $size-120;
  width: $size-120 !important;
}

%icon-addon {
  background-color: inherit;
  display: inline-block;
  text-align: center;
}

%jr-card-style {
  background-color: gray('600');
  color: $white;
  margin-bottom: $jr-card-margin;
  padding: $jr-card-padding;
  position: relative;
  @include border-radius($border-radius);
  @include box-shadow($jr-card-shadow);
}

/* Ripple magic */
%ripple-effect {
  overflow: hidden;
  position: relative;

  &:after {
    background: rgba($white, 0.5);
    content: '';
    height: 5px;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: scale(1, 1) translate(-50%);
    transform-origin: 50% 50%;
    width: 5px;
    @include border-radius(100%);
  }

  &:hover:after {
    animation: ripple 1s ease-out;
  }
}

@keyframes ripple {
  0% {
    opacity: 0.5;
    transform: scale(0, 0);
  }
  20% {
    opacity: 0.3;
    transform: scale(60, 60);
  }
  100% {
    opacity: 0;
    transform: scale(100, 100);
  }
}

@-webkit-keyframes ripple {
  0% {
    opacity: 0.5;
    transform: scale(0, 0);
  }
  20% {
    opacity: 0.3;
    transform: scale(60, 60);
  }
  100% {
    opacity: 0;
    transform: scale(100, 100);
  }
}

//Pulse Effect
%pulse-effect {
  animation: pulse 2s infinite;
  display: block;
  @include box-shadow(0 0 0 rgba($danger, 0.4));
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba($danger, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba($danger, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba($danger, 0);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($danger, 0.4);
    -moz-box-shadow: 0 0 0 0 rgba($danger, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba($danger, 0);
    -moz-box-shadow: 0 0 0 10px rgba($danger, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($danger, 0);
    -moz-box-shadow: 0 0 0 0 rgba($danger, 0);
  }
}

//Online Effect
%online-effect {
  animation: online 2s infinite;
  display: block;
  @include box-shadow(0 0 0 rgba($green, 0.4));
}

@-webkit-keyframes online {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba($green, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba($green, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba($green, 0);
  }
}

@keyframes online {
  0% {
    box-shadow: 0 0 0 0 rgba($green, 0.4);
    -moz-box-shadow: 0 0 0 0 rgba($green, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba($green, 0);
    -moz-box-shadow: 0 0 0 10px rgba($green, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($green, 0);
    -moz-box-shadow: 0 0 0 0 rgba($green, 0);
  }
}

//Away Effect
%away-effect {
  animation: away 2s infinite;
  display: block;
  @include box-shadow(0 0 0 rgba($yellow, 0.4));
}

@-webkit-keyframes away {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba($yellow, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba($yellow, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba($yellow, 0);
  }
}

@keyframes away {
  0% {
    box-shadow: 0 0 0 0 rgba($yellow, 0.4);
    -moz-box-shadow: 0 0 0 0 rgba($yellow, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba($yellow, 0);
    -moz-box-shadow: 0 0 0 10px rgba($yellow, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($yellow, 0);
    -moz-box-shadow: 0 0 0 0 rgba($yellow, 0);
  }
}

/*Different size images set in Equal block height and width*/
%thumb-equal {
  height: 0;
  overflow: hidden;
  padding-bottom: 68%;
  position: relative;
  width: 100%;
}

%thumb-cover {
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
}

%thumb-cover-img {
  height: auto;
  max-width: 100%;
  min-height: 100%;
  object-fit: cover;
  width: 100%;
}

// Hover Effect On Bootstrap Components
%link-hover {
  color: $white !important;

  &:hover,
  &:focus {
    color: $white !important;
  }
}

// Pointer
%pointer {
  cursor: pointer;
}
