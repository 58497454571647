/*Cards Styles*/
.profile-intro {
  @include border-radius($border-radius !important);
  background-color: $white;
  overflow: hidden;

  &:hover {
    @include box-shadow($box-shadow-lg);

    & .avatar-circle {
      @include border-radius(25% !important);
      @include transition(border-radius 0.4s ease 0.1s);
    }
  }

  img.avatar-circle {
    -ms-transform: translateY(50px);
    -webkit-transform: translateY(50px);
    @extend %size-120;
    @include border-radius(50% !important);
    @include box-shadow($box-shadow);
    @include transition(all 0.4s ease-in-out);
    display: inline-block;
    margin: 0 auto;
    position: relative;
    transform: translateY(50px);
    z-index: 1;
  }
}

.profile-intro {
  & .card-image {
    min-height: 100%;
    position: relative;
    width: 100%;
  }

  & .pi-content {
    padding: 70px 40px 24px !important;
  }

  & .pi-footer {
    padding: 0 40px 24px !important;
  }

  & .icon {
    @include transition(all 0.2s ease 0s);
    color: theme-color('primary');
    display: inline-block;

    &:hover,
    &:focus,
    &:active {
      -ms-transform: scale(1.2);
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  }

  & .icon:not(:last-child) {
    margin-right: 10px;
  }
}

.layer {
  &:before {
    background-color: rgba($black, 0.6);
    bottom: 0;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.jr-card-less-height,
.jr-card {
  @extend %jr-card-style;

  @media screen and (max-width: 575px) {
    margin-bottom: 18px;
    padding: 20px;
  }

  & .jr-card-thumb {
    margin: (-$jr-card-padding) (-$jr-card-padding) 0;

    @media screen and (max-width: 575px) {
      margin: -20px -20px 0;
    }
  }

  &-full {
    padding: 0;
  }

  &-equalheight {
    @include display-flex(flex, column, nowrap);
    height: calc(100% - #{$jr-card-margin});

    @media screen and (max-width: 575px) {
      height: calc(100% - 18px);
    }

    & .jr-card-body {
      @include align-items(center);
      @include display-flex(flex, column, nowrap);
      @include justify-content(center);
      flex: 1;
      & .recharts-responsive-container {
        height: auto !important;
        width: auto !important;
      }

      & .responsive-chart {
        @include align-items(center);
        @include display-flex(flex, column, nowrap);
        @include justify-content(center);
        height: 100% !important;
        width: 100% !important;
      }
    }
  }
}

.jr-card-fixed {
  height: calc(100vh - 270px);
  margin-bottom: 0 !important;
  overflow: auto;
  padding-right: 1rem;

  &::-webkit-scrollbar {
    height: 9px;
    width: 9px;
  }
}

.jr-card-less-height {
  margin-bottom: 10px;
  padding: 10px 50px;
}

.jr-card-less-height-content {
  margin-bottom: 10px;
  padding: 10px 50px;

  @include mq(laptop) {
    padding: 10px !important;
  }
}

.jr-card-filter {
  background-color: $vpag-dark;
  border-radius: 0.375rem 0.375rem 0 0;
  padding: 0.3rem;

  @include mq(tablet) {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

.jr-card-bar {
  background-color: $vpag-dark;
  padding: 0.3rem;

  @include mq(tablet) {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

.jr-card-header {
  margin-bottom: calc($jr-card-margin / 1.5);

  .card-heading {
    font-size: 17px;
    margin-bottom: calc($jr-card-margin / 12);
  }

  & .badge {
    margin-bottom: 0;
    padding: 2px 5px;

    &:last-child {
      margin-left: 10px;
    }
  }

  @media screen and (max-width: 575px) {
    margin-bottom: 18px;
  }

  & .icon-btn:last-child {
    -ms-flex-align: start;
    align-self: flex-start;
    margin-right: -12px;
    margin-top: -10px;
  }
}

.jr-entry-header {
  margin-bottom: calc($jr-card-margin / 1.5);

  .entry-heading {
    margin-bottom: calc($jr-card-margin / 6);
  }

  .entry-description {
    color: $gray-600;
    font-size: 13px;
    margin-bottom: 0;
  }

  @media screen and (max-width: 575px) {
    margin-bottom: 12px;
  }
}

.card {
  @include border-radius($border-radius);
  border: 0 none;
  margin-bottom: $jr-card-margin;

  @media screen and (max-width: 575px) {
    margin-bottom: 18px;
  }
}

.card-header {
  font-size: 16px;

  .jr-card-intra & {
    background-color: rgba($black, 0.02);
    border-bottom: 0 none;
  }

  & .badge {
    margin-bottom: 0;
    padding: 2px 5px;

    &:last-child {
      margin-left: 10px;
    }
  }
}

.card-title {
  font-size: $h3-font-size;
  text-transform: capitalize;

  .jr-card-metrics & {
    font-size: $h6-font-size;
    margin-bottom: 15px;
    text-transform: uppercase;
  }

  .jr-card-widget & {
    font-size: $h6-font-size;
    text-transform: uppercase;
  }

  @media screen and (max-width: 1366px) {
    font-size: $h4-font-size;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 10px;
  }
}

.jr-card-intra {
  border: 0 none;
}

.product-item-vertical .card-footer {
  padding-left: 0;
  padding-right: 0;
}

.card-subtitle {
  color: $gray-700;
  font-size: 14px;

  &:not(:last-child) {
    margin-bottom: $h3-font-size;
  }
}

.card-mt-footer {
  margin-top: -10px;
  padding: 0 10px 14px;
}

.jr-card-social {
  font-size: 22px;
  padding-top: 15px;
}

.social-link {
  @include align-items(center);
  @include display-flex();
  list-style: none;
  margin: 0 -5px 10px;
  padding-left: 0;
  & li {
    margin-bottom: 5px;
    padding: 0 5px;
  }

  & li a {
    color: $body-color;
  }

  & li.active a,
  & li a:hover,
  & li a:focus {
    color: $secondary;
  }

  .jr-card-social & {
    @include justify-content(space-between);
    display: flex !important;
    margin-bottom: 0;
  }

  .jr-card-social-around & {
    @include justify-content(space-around);
  }
}

.jr-cart-ab {
  @include align-items(center);
  @include display-flex();
  @include justify-content(space-around);
  bottom: 0;
  color: $white;
  font-size: $h3-font-size;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

  & i {
    font-size: 25px;
    vertical-align: middle;
  }
}

.img-overlay-card {
  @include border-radius($border-radius);
  margin-bottom: $jr-card-margin;

  & .center-crop-img {
    min-height: 110px;
  }

  @media screen and (max-width: 1199px) {
    & .center-crop-img {
      min-height: 135px;
    }
  }

  @media screen and (max-width: 575px) {
    margin-bottom: 18px;
  }
}

.jr-card-overview {
  @include border-radius($border-radius-lg);
  @include box-shadow($jr-card-shadow);
  background-color: $white;
  margin-bottom: $jr-card-margin;
  padding: $jr-card-padding;
  position: relative;

  & .jr-card-title {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 575px) {
    margin-bottom: 18px;
    padding: 20px;
  }
}

.card-img {
  border-radius: $border-radius;
}

.card-img-top {
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}

.card-img-bottom {
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

.card-header:first-child {
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}

.card-footer:last-child {
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

.jr-full-card {
  padding: 0;

  & .jr-card-header {
    margin-bottom: 0;
    margin-top: 0;
    padding: $jr-card-padding $jr-card-padding 26px;

    @media screen and (max-width: 575px) {
      padding: 20px;
    }
  }

  & .table-responsive-material {
    padding-bottom: 20px;

    @media screen and (max-width: 575px) {
      padding-bottom: 15px;
    }
  }
}

.jr-weather-card {
  padding: 0;

  & .jr-card-header {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    margin-bottom: 0;
    overflow: hidden;
    padding: 40px 20px;
    position: relative;
    text-align: center;
    &:before {
      background-color: rgba($black, 0.5);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    & * {
      position: relative;
      z-index: 2;
    }

    & .card-heading {
      font-size: $h1-font-size;
      margin-bottom: 5px;

      @media screen and (max-width: 1366px) {
        font-size: ($h2-font-size + 0.125rem);
      }
    }

    & .sub-heading {
      color: $white;
    }
  }

  & .jr-card-body {
    padding: $jr-card-padding;

    @media screen and (max-width: 575px) {
      padding: 20px;
    }
  }
}
