/*Alert Styles*/
.alert {
  border: 0 none;

  & > :last-child {
    margin-bottom: 0;
  }
}

.alert {
  @include border-radius($border-radius-sm);
  padding: 20px 24px;
  position: relative;

  @media screen and (max-width: 575px) {
    padding: 16px;
  }

  & .close {
    font-weight: $font-weight-light;
    line-height: inherit;
  }

  & .alert-addon {
    @include align-items(center);
    @include display-flex(flex, column, nowrap);
    @include justify-content(center);
    background-color: rgba($black, 0.2);
    bottom: 0;
    left: 0;
    position: absolute;
    top: 0;
    width: $size-60;
    z-index: 1;
  }

  &-dismissible {
    padding-right: $size-60;

    @media screen and (max-width: 575px) {
      padding-right: $size-40;

      & .close {
        padding-left: 10px;
        padding-right: 15px;
      }
    }
  }

  &-addon-card {
    padding-left: $size-80;

    @media screen and (max-width: 575px) {
      padding-left: $size-70;
    }
  }
}

.expired-session {
  align-items: center;
  background-color: gray('900');
  color: $white;
  display: flex;
  font-family: $font-family-base;
  justify-content: space-between;
  max-width: 550px;
  min-height: 140px;
  padding: 1rem 2rem;
  position: relative;
  width: 80vw;

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__icon {
    margin: 0 -3rem 0 1rem;
    width: 60px;
  }

  &__title {
    font: 18px $font-family-bold;
  }

  @include mq(tablet) {
    align-items: center;
    flex-direction: column;
    padding: 2rem 0;
    text-align: center;
    width: 80vw;

    &__icon {
      margin: 0;
      width: 60px;
    }
  }
}
