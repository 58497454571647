/*Avatar Styles*/
.user-avatar {
  @extend %size-60;

  & img {
    height: auto;
    max-width: 100%;
  }

  &-lg {
    @extend %size-60;
    margin-right: 10px;
  }
}

.capitalize {
  text-transform: capitalize;
}

.user-detail {
  & .user-name {
    font-weight: 400;
    max-width: 180px !important;
  }

  & .user-description {
    color: $light-gray;
    font-size: 13px;
    margin-bottom: 0;
  }
}
