/*Sidebar Styles*/

.side-nav {
  background-color: $sidebar-bg !important;
  border-right: 0 none !important;
  color: $sidebar-text-color !important;
  width: $side-nav-width;
  z-index: 1250 !important;

  & .user-profile {
    align-items: center;
    align-self: center;
    background-color: rgba(gray('900'), 0.5) !important;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 5px;
    padding: 5px 0;
    width: 95%;
  }

  & .user-avatar {
    height: 44px !important;
    line-height: 44px;
    margin: 0 15px 0 0 !important;
    width: 44px !important;
  }

  & .user-detail {
    cursor: pointer;

    & .user-name {
      color: $sidebar-hover-color;
      font-size: $font-size-lg;
      font-weight: 400;
      max-width: 190px;
      text-overflow: clip;
      white-space: nowrap;
    }

    & .user-role {
      color: $sidebar-hover-color;
      font-size: $font-size-sm;
      font-weight: 500;
      white-space: nowrap;
    }

    & .user-email {
      color: gray('600');
      font-size: $font-size-sm;
      font-weight: 400;
      white-space: nowrap;

      span {
        max-width: 180px;
      }
    }
  }

  & .menu-collapse {
    justify-content: center;
    padding-top: 0.5rem;
    width: 90%;

    .menu-item {
      color: gray('100');
      font-family: $font-family-base;
      font-size: 14px;
      padding: 0.8rem 2rem;
      width: 100%;

      &:first-child,
      &:last-child {
        border-top: 1px solid rgba(225, 217, 205, 0.2);
      }
    }
  }
}

.side-nav-menu {
  font-size: $font-size-base;
}

.nav-section {
  position: relative;

  & .nav-header {
    border-top: solid $border-width lighten($sidebar-bg, 6%);
    color: $sidebar-text-color;
    font-size: 11px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
    padding-bottom: 10px;
    padding-top: 40px;
    text-transform: uppercase;
  }

  &:first-child {
    & .nav-header {
      border-top: 0 none;
      margin-top: 10px;
      padding-top: 10px;
    }
  }

  & .nav-menu-item {
    padding: 0;
  }

  & .nav-collapse,
  & .nav-menu-item {
    margin-bottom: 1px;
  }
}

.nav-collapse {
  position: relative;

  & &-btn {
    color: $sidebar-text-color;
    font-size: 14px;
    letter-spacing: 0.02857em;
    line-height: 1.75;
    padding: 7px 30px 8px 20px;

    &:focus,
    &:hover {
      background-color: $sidebar-bg-darken;
      color: $sidebar-hover-color;
    }
  }

  &.open {
    background-color: $sidebar-bg-darken;

    & .nav-collapse-btn {
      background-color: $sidebar-bg-darken;
      color: $sidebar-hover-color;

      &:focus,
      &:hover {
        background-color: $sidebar-bg-darken;
        color: $sidebar-hover-color;
      }
    }
  }

  & i {
    font-size: 15px;
    margin-right: 12px;
  }

  & .nav-text {
    flex: 1;
  }

  & .nav-arrow {
    font-size: 18px;
  }
}

.nav-collapse {
  & .nav-menu-item .nav-menu-link {
    color: $sidebar-text-color;
    margin-right: 20px;
    padding-left: 52px;
    position: relative;
    @include border-right-radius($border-radius-xxl);

    &:focus,
    &:hover,
    &.active {
      background-color: $sidebar-bg-darken;
      color: $sidebar-hover-color;
    }

    &.active:after {
      display: inline-block;
    }
  }

  &.open {
    & .nav-menu-item .nav-menu-link.active {
      color: $sidebar-hover-color;
    }
  }

  & .nav-collapse {
    & .nav-collapse-btn {
      padding-left: 52px;
    }

    & .nav-menu-item .nav-menu-link {
      padding-left: 72px;
    }

    & .nav-collapse {
      & .nav-menu-item .nav-menu-link {
        padding-left: 92px;
      }

      & .nav-collapse-btn {
        padding-left: 72px;
      }
    }
  }
}

.nav-menu-item {
  & .nav-menu-link {
    color: $sidebar-text-color;
    display: block;
    font-size: 14px;
    letter-spacing: 0.02857em;
    line-height: 1.5;
    padding: 7px 30px 8px 20px;
    text-decoration: none;

    &:focus,
    &:hover,
    &.active {
      background-color: transparent;
      color: $sidebar-hover-color;
    }
  }

  & i {
    font-size: 15px;
    margin-right: 12px;
  }
}

.nav-menu-divider {
  align-items: center;
  display: flex;
  font-size: 10px;
  padding: 0 30px 0 45px;
  text-transform: uppercase;

  &::after,
  &::before {
    background-color: gray('700');
    content: '';
    flex-grow: 0.25;
    height: 1px;
  }

  &::after {
    flex-grow: 1;
  }
}

.nav-menu-divider:not(:empty)::before {
  margin-right: 0.25em;
}

.nav-menu-divider:not(:empty)::after {
  margin-left: 0.25em;
}

.app-container.mini-drawer {
  & .side-nav {
    width: $mini-drawer-width;
    @include transition($transition-base);

    &:not(:hover) {
      & .nav-collapse .nav-collapse-btn,
      & .nav-menu-item .nav-menu-link {
        padding: 12px 10px;
      }

      & .nav-section {
        & .nav-header {
          margin-left: 10px;
          margin-right: 10px;
        }

        &:not(:first-child) .nav-header {
          margin-top: 10px;
          padding-top: 20px;
        }
      }

      & .side-nav-menu .nav-text,
      & .side-nav-menu .nav-arrow,
      & .user-profile .user-detail,
      & .side-nav-menu .nav-collapse.open > .nav-collapse-item {
        display: none;
      }

      & .side-nav-menu .nav-section {
        text-align: center;
      }

      & .side-nav-menu .nav-section .nav-collapse-btn {
        justify-content: center;
        text-align: center;
      }

      & .side-nav-menu .nav-section i,
      & .side-nav-menu .nav-section .zmdi {
        margin-right: 0;
      }
    }
  }
}

.app-container.mini-drawer {
  & .side-nav {
    overflow: hidden auto;
  }

  & .side-nav:hover {
    width: $side-nav-width;
  }
}

.customizer {
  padding: 20px 35px;

  & img {
    cursor: pointer;
    padding: 5px;
  }
}

.user-info {
  & ul {
    & li {
      font-size: 15px;
    }
  }
}

.nav-menu-option {
  span {
    font-size: $font-size-sm !important;
  }
}
