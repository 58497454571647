// Form Fiel styles

.checkbox {
  display: block;
  padding-left: 9px;

  .container {
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    color: $white;
    cursor: pointer;
    display: block;
    font-size: 14px;
    position: relative;
    user-select: none;
  }

  .container input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }

  .checkmark {
    background-color: transparent;
    border: solid 1px $vpag-primary;
    border-radius: 4px;
    height: 18px;
    left: -7px;
    position: absolute;
    top: 0;
    width: 18px;
  }

  .container input:checked + .checkmark {
    background-color: $vpag-primary;
  }

  .container:hover input ~ .checkmark {
    filter: brightness(0.9);
  }

  .checkmark:after {
    content: '';
    display: none;
    position: absolute;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    border: solid gray('900');
    border-width: 0 2px 2px 0;
    height: 10px;
    left: 5px;
    top: 2px;
    transform: rotate(45deg);
    width: 5px;
  }
}

.switch,
.switch-sm,
.switch-xs {
  appearance: none;
  background-color: gray('700');
  border-radius: 2rem;
  cursor: pointer;
  height: 20px;
  position: relative;
  transition: 0.2s;
  width: 38px;

  &::after {
    background-color: gray('100');
    border-radius: 50%;
    content: '';
    height: 20px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    transition: 0.2s;
    width: 20px;
  }

  &:checked::after {
    background-color: $vpag-primary;
    transform: translate(90%, -50%);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.secondary {
    &:checked::after {
      background-color: $vpag-pink;
    }
  }

  &.danger {
    &:checked::after {
      background-color: $vpag-danger;
    }
  }
}

.switch-sm {
  height: 20px;
  width: 38px;

  &::after {
    height: 19px;
    left: 5%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 19px;
  }
}

.switch-xs {
  height: 18px;
  width: 32px;

  &::after {
    height: 16px;
    left: 5%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 16px;
  }
}

.create-user {
  &__container {
    background-color: $vpag-dark;
  }

  &__input-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    padding: 1.5rem 1rem;

    @include mq(laptop) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }

  &__image-container {
    align-items: center;
    color: gray('100');
    display: flex;
    gap: 1rem;
    opacity: 0.7;
    padding: 1rem;

    div {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      gap: 0.5rem;

      span {
        width: 100%;
      }
    }

    label {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: 80px;
      justify-content: center;
      opacity: 0.6;
      width: 80px;

      input {
        display: none;
      }

      img {
        width: 100px;
      }
    }
  }

  &__create-button {
    background-color: $vpag-primary;
    border-radius: 5px;
    border: none;
    color: gray('900');
    cursor: pointer;
    font-family: $font-family-bold;
    height: 30px;
    padding: 0 1rem;

    &.disabled {
      background: rgba(225, 217, 205, 0.2);
      opacity: 1;
    }
  }

  @media screen and (max-width: 420px) {
    &__input-container {
      align-items: center;
      flex-direction: column;

      label {
        width: 100%;
      }
    }
  }
}

.filter-input {
  &.large {
    input {
      width: 225px;
    }
  }
}

.input-password {
  position: relative;

  span {
    color: gray('100') !important;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 15px;
    z-index: 99;
  }
}

.input-gray,
.input-gray-smh,
.input-dark,
.select-gray {
  background-color: gray('800');
  border: none;
  color: gray('100');
  font-family: $font-family-base;
  padding: 0.4rem;
  text-align: center;

  &::placeholder {
    color: gray('50');
    font-size: 12px;
    letter-spacing: normal !important;
  }
}

.input-border-error {
  border: 1px solid $danger;
}

.code-input-field {
  @extend .input-gray;
  border-radius: 3px;
  font-size: 24px;
  height: 40px;
  width: 30px;
}

.form-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.7rem;
  width: 240px;

  &.error {
    input,
    select {
      border: solid 1px $danger !important;
    }
  }

  &.sm {
    width: 80px;
  }

  &.md {
    width: 160px;
  }

  &.lg {
    width: 320px;
  }

  &.full-size {
    width: 100%;
  }

  &__input {
    background-color: gray('700');
    border: none;
    color: gray('100');
    font-size: $font-size-base;
    padding: 0.5rem 1rem;
    outline: none;
    width: 100%;

    &::placeholder {
      color: gray('100');
      font-size: 16px;
      letter-spacing: 0 !important;
      opacity: 0.8;
      text-align: center;
    }
  }

  &__label {
    color: gray('100');
    font-size: $font-size-xs;
    text-transform: uppercase;
    width: 100%;
  }

  &__error-message {
    max-width: 230px;
    font-size: $font-size-sm;
  }

  &__option-placeholder {
    color: rgba(gray('100'), 0.5);
  }

  select,
  input {
    min-height: 40px;
  }
}

.disabled-modal-input {
  &:disabled {
    filter: brightness(0.8);
    user-select: none;
  }
}

.status-select {
  margin-left: 6px;
  position: relative;

  &__collapse-btn {
    background-color: transparent;
    border: none;
    color: $white;
  }

  &__collapse {
    animation: opacityAnimation 0.2s linear;
    background-color: gray('900');
    border-radius: 6px;
    left: -15px;
    position: absolute;
    top: 30px;
    width: 130px;
    z-index: 999;
  }

  &__item {
    display: flex;
    justify-content: center;
    padding: 0.3rem 0;
    width: 100%;

    &:hover {
      filter: brightness(1.1);
    }
  }
}
