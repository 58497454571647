/*Color Picker Styles*/

.cp-color {
  border-radius: 2px;
  height: 14px;
  width: 36px;
}

.cp-swatch {
  @include box-shadow(0 0 0 1px rgba($black, 0.1));
  background: $white;
  border-radius: 1px;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
}

.cp-popover {
  position: absolute;
  z-index: 2;
}

.cp-cover {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.photoshop-picker,
.swatches-picker,
.alpha-picker,
.hue-picker {
  width: 100% !important;
}

.material-picker {
  height: 100% !important;
  width: 100% !important;
}
