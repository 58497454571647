/*Progressbar Styles*/

.pb-wrapper {
  align-items: center;
  display: flex;
}

.pb-btn-wrapper {
  margin: 8px;
  position: relative;

  .btn-success {
    background-color: map_get($green, base);

    &:hover {
      background-color: map_get($green, darken-2);
    }
  }
}

.fab-progress {
  color: map_get($green, base);
  left: -6px;
  position: absolute;
  top: -6px;
  z-index: 1;
}

.btn-progress {
  color: map_get($green, base);
  left: 50%;
  margin-left: -12px;
  margin-top: -12px;
  position: absolute;
  top: 50%;
}

.progress-height {
  height: 6px !important;
}
