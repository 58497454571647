/*Filter styles*/

.filter-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 50px;

  .clear-btn,
  .download-btn {
    background-color: transparent;
    border: none;
    color: $vpag-primary;
    font-weight: 600;
    text-decoration: underline;
  }

  .collapse-btn {
    background-color: transparent;
    border: none;
    color: $white;
    font-weight: 600;
    text-decoration: underline;
    min-width: 58px;
  }

  .chip {
    svg {
      color: $vpag-primary;
      height: 15px;
      width: 15px;
    }
  }

  &--title-wrapper {
    align-items: center;
    display: flex;
    height: 52px;
    position: relative;
  }
}

.filter-options {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.download-btn-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: 50px;
}