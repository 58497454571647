/*Main Content Styles*/
.app-main-content {
  flex: 1;
  width: 100%;
}

.app-wrapper,
.app-wrapper-less-top-pad,
.app-wrapper-less-bottom-pad {
  padding: 15px 30px 0 30px;
  width: 100%;

  @media screen and (max-width: 575px) {
    padding: 20px;
  }
}

.app-wrapper-less-bottom-pad {
  padding: 30px 30px 0;
}

.app-wrapper-less-top-pad {
  padding: 0 30px 30px;
}
