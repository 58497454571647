/*Tabs Styles*/

.tab {
  font-size: 14px !important;
  min-width: 10px !important;
}

.tab-icon > span {
  @include display-flex();
}

.jr-tabs-classic {
  position: relative;

  & .jr-tabs-content {
    padding-top: 30px;
    position: relative;

    &:before {
      border-bottom: solid $border-width rgba($white, 0.4);
      content: "";
      height: 1px;
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;
      top: 0;
      width: 95%;
    }
  }
}

.jr-card-title {
  &:after {
    border-bottom: solid $border-width rgba($white, 0.4);
    content: "";
    height: 1px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 60px;
    width: 95%;
  }
}

.jr-tabs-classic-no-border {
  & .jr-tabs-content:before {
    display: none;
  }
}

.jr-tabs-up {
  position: relative;

  & .jr-tabs-label {
    min-width: 70px;
  }

  @media screen and (min-width: 576px) {
    margin-left: 100px;
    margin-right: 30px;
    margin-top: -40px;
  }
}

.jr-tabs-pills-ctr {
  @include justify-content(center);

  & .nav-link {
    padding: 2px 14px;
  }

  & .nav-pills .nav-link {
    @include border-radius($border-radius-sm);
  }
}

.jr-card-tabs-right {
  & .jr-tabs-up {
    margin-left: auto;
    max-width: 180px;

    @media screen and (max-width: 991px) {
      max-width: 260px;
    }

    @media screen and (max-width: 575px) {
      margin-left: 0;
      max-width: none;
    }
  }
}
