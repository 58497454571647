//Profile App Style
.jr-profile-banner {
  background-color: $app-primary;
  border-radius: 10px;
  color: $white;
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  min-height: 110px;
  padding: 10px 50px;
  position: relative;
  user-select: none;

  & > div {
    position: relative;
    z-index: 2;
    @include mq(mobile) {
      padding: 0 1rem;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: $white;
  }

  & .jr-link {
    color: $white;
    cursor: pointer;

    &:hover,
    &:focus {
      color: $secondary;
    }
  }

  &--title-wrapper {
    align-items: center;
    display: flex;
    gap: 1rem;
  }

  &--title {
    color: gray('600') !important;
    font: 24px $font-family-bold !important;
    margin: 0;
  }

  &--status-wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &--avatar {
    @include mq(mobile) {
      display: none !important;
    }
  }

  &--create-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  @include mq(tablet) {
    padding: 10px;

    &--title-wrapper {
      align-items: flex-start;
      flex-direction: column;
      gap: 1rem;
    }

    &--status-wrapper {
      margin: 1rem 1rem 0 0;
    }
  }

  @include mq(mobileLarge) {
    padding: 10px;

    .jr-profile-banner--return-btn {
      display: none;
    }

    &--title {
      font-size: 18px !important;
    }
  }

  @include mq(mobile) {
    &--avatar {
      display: none !important;
    }
  }
}

.jr-profile-banner {
  display: flex;
}

.jr-profile-banner-top {
  @include align-items(center);
  @include display-flex(flex, row, wrap);

  @media (max-width: 575px) {
    margin-bottom: 25px;
    @include display-flex(flex, column, nowrap);
  }
}

.jr-profile-banner-top-left {
  @include align-items(center);
  @include display-flex(flex, row, wrap);

  @media (max-width: 575px) {
    margin-bottom: 25px;
    @include display-flex(flex, column, nowrap);
  }
}

.jr-profile-banner-avatar {
  margin-right: 1.5rem;

  @media (max-width: 575px) {
    margin-bottom: 8px;
    margin-right: 0;
  }
}

.jr-profile-banner-top-right {
  margin-left: auto;

  @media (max-width: 575px) {
    margin-left: 0;
  }
}

.jr-profile-banner-bottom {
  @include align-items(center);
  @include display-flex(flex, row, wrap);
  justify-content: flex-end;

  @media (max-width: 575px) {
    @include display-flex(flex, column, nowrap);

    .jr-tab-list {
      margin-bottom: 10px;
    }
  }
}

.enabled-user,
.disabled-user {
  background-color: $vpag-sucess;
  border-radius: 50%;
}

.disabled-user {
  background-color: $vpag-danger;
}

.absolute-modal {
  height: 100%;
  position: absolute;
  width: 100%;
}

.absolute-bottom {
  bottom: 0;
  position: absolute;
  z-index: 99;
}

.absolute-center {
  left: 30%;
  position: absolute;
  top: 30%;
}

.absolute-top-right,
.absolute-top-left {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
}

.absolute-bottom-left {
  bottom: 0;
  left: 0;
  position: absolute;
}

.min-height-module-box {
  min-height: 45px;
}

.text-button {
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
}

.absolute-top-left {
  left: 0;
}

.absolute-bottom-menu,
.inactive-item {
  background-color: $white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: auto;
  left: 90%;
  opacity: 1;
  position: absolute;
  top: 0;
  transition: 0.2s;
  width: 110%;
  z-index: 99;

  span {
    color: $black;
    cursor: pointer;
    padding: 8px 10px;

    &:hover {
      color: $secondary;
    }
  }
}

.inactive-item {
  opacity: 0;
}

.jr-profile-content {
  position: relative;
  z-index: 2;
}

.jr-profile-setting {
  margin-left: auto;

  @media (max-width: 575px) {
    margin-left: 0;
  }
}

.jr-profile-content-right {
  @media (max-width: 575px) {
    @include align-items(center);
    @include display-flex(flex, row, wrap);

    .text-truncate {
      margin-left: auto;
      width: auto;
    }
  }
}

.jr-pro-contact-list {
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.jr-card-profile {
  border-radius: 10px;
  position: relative;

  &-sm {
    position: relative;
  }

  & .card-header {
    background-color: transparent;
    border-bottom: 0 none;
    padding: 18px $jr-card-padding;

    @media (max-width: 575px) {
      border-bottom: solid 1px $border-color;
    }
  }

  & .card-title {
    font-size: 18px;
    text-transform: capitalize;
  }

  & .jr-tabs-up {
    margin-top: -50px;

    @media (max-width: 575px) {
      margin-left: 30px;
      margin-right: 30px;
      margin-top: 0;
    }
  }

  & .jr-tabs-content {
    padding: 10px $jr-card-padding 20px;
  }
}

.small-text {
  font-size: 12px;
}

.less-weight {
  font-weight: 500 !important;
}

.v-pag-link {
  font-weight: 600 !important;
  text-decoration: underline;
  color: $vpag-primary !important;
}

.profile-details {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  &--items-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;

    @include mq(laptop) {
      grid-template-columns: repeat(2, 1fr);
      justify-content: center;
    }

    @include mq(mobileLarge) {
      grid-template-columns: 1fr;
    }
  }

  &--icon {
    color: $vpag-primary;
    font-size: 2.5rem;
  }

  &--content {
    font-weight: 600;
    max-width: 155px;
  }

  &--form {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-around;
    padding: 1rem;
    width: 100%;
  }

  &--label {
    color: gray('100');
    display: flex;
    flex: 0.5;
    flex-direction: column;
    font-size: 12px;
    gap: 0.2rem;
    max-width: 250px;
    min-width: 200px;

    &:disabled {
      opacity: 0.5;
    }

    select {
      height: 37px;
    }
  }

  &--input {
    background-color: gray('700');
    border: none;
    color: gray('100');
    font-size: $font-size-base;
    padding: 0.5rem 1rem;
  }
}
